.inner-content{
    display: grid;
    grid-template-columns: 35% 35% !important;
    min-width: fit-content;
}

@media (max-width: 1030px) {
    .inner-content {
        grid-template-columns: 35% 60% !important;
    }
}

@media (max-width: 800px) {
    .inner-content {
        grid-template-columns: repeat(1, 1fr) !important;
    }
}