@import url("../../../assets/styles/fonts.css");

.NotificationsBody {
    color: var(--themeFontColor) !important;
}

.NotificationTitle {
    font-weight: bolder;
    font-size: 20px;
    padding: 2% 0%;
    font-family: 'Gilroy-SemiBold' !important;
}

.NotificationTitleInfo {
    font-weight: bold;
    text-transform: uppercase;
}

.Notification_RequirementListContainer {
    box-shadow: 0px 1px 10px 0px rgba(68, 126, 183, 0.25);
    padding: 2%;
    margin-top: 2%;
    background: var(--themePrimaryColor) !important;
    border-radius: 10px;
}

/* thead[class*="ant-table-thead"] th{
    background-color: #447EB7 !important;
    color: white !important;
} */ /* this is for the table header, which works but isn't dynamic */

@media (max-width: 1100px) {
    .NotificationGridBlock {
        width: 90%;
    }

    .InvoicingInstruction{
        width: 60%;
    }
}

@media (max-width: 770px) {
    .NotificationDetailsGrid {
        grid-template-columns: repeat(1, 1fr) !important;
    }

    .NotificationGridBlock {
        width: 100%;
    }

    .InvoicingInstruction{
        grid-template-columns: repeat(1, 1fr);
        width: 100%;
    }
}

.InstructionsTitle {
    text-transform: uppercase;
    font-weight: bold;
    padding: 2% 0% 1%;
    font-size: large;
    font-family: 'Gilroy-SemiBold' !important;
}