
@import url("../../../assets/styles/fonts.css");

.Notification {
    min-height: 79vh;
    font-size: large;
    color: var(--themeFontColor) !important;
    font-family: 'Gilroy-SemiBold' !important;
}

.NotificationTitle {
    font-weight: bolder;
    font-size: x-large;
    text-transform: uppercase;
}

.Notification-ContactDetails {
    padding: 2% 0%;
    font-weight: bold;
    font-size: medium;
}

.Notification_TextTransform {
    text-transform: uppercase;
}

.Notification_RequirementList {
    width: 60%;
    margin: auto;
    text-align: left;
}

@media (max-width: 700px) {
    .Notification_RequirementList {
        width: 100%;
    }
}

.Notification_RequirementListContainer {
    box-shadow: 0px 1px 10px 0px rgba(68, 126, 183, 0.25);
    padding: 2%;
    margin-top: 2%;
    background: var(--themePrimaryColor) !important;
    border-radius: 10px;
}

.Notification_IndividualBlock {
    display: flex;
    align-items: center;
    height: 40px;
}

.Notification_IndividualBlockGrid {
    display: grid;
    grid-template-columns: 40px auto;
    grid-gap: 5%;
    width: 100%;
}

.Notification_WidthCenter {
    display: flex;
    justify-content: center;
}

.Notification_HeightCenter {
    display: flex;
    align-items: center;
}

.Notification_Bold {
    font-weight: bold;
    font-family: 'Gilroy-SemiBold' !important;
}

.Notification_Divider {
    margin: 10px 0px;
}

.Notification_ButtonDiv {
    padding: 3%;
}

.Notification_Button {
    height: 40px;
}