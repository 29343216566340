.container {
    background: var(--themePrimaryColor) !important;
    padding: 2%;
    box-shadow: 0px 1px 10px 0px rgba(68, 126, 183, 0.25);
}

.PaymentSchedule_Description {
    text-align: start;
    margin: 0% 0% 1% 0%;
    color: var(--themeFontColor) !important;
}

.contentHeader {
    display: flex;
    font-size: 22px !important;
}