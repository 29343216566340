.horizontalDivider-container {
    width: auto;
    margin: 0.5rem !important;
    padding: 0.5rem !important;
}

.horizontalDivider-title {
    text-align: left;
    font-weight: 350;
    font-size: 26px;
    margin: 0.25rem !important;
    color: var(--themeFontColor) !important;
}

@media (max-width: 700px) {
    .horizontalDivider-title {
        font-size: 20px;
    }
}

.dividerStyle {
    margin: 0;
    margin-bottom: 0.5rem !important;
    color: var(--themeFontColor) !important;
}