.Kebab {
    fill: var(--userPrimaryColor) !important;
}

.Kebab:hover {
    /* Commenting the hover color as the color will match the Table Row hover background */
    /* fill: var(--userHighlightColor) !important; */
    fill: var(--userPrimaryColor) !important;
}

.Kebab.disabled {
    fill: var(--userHighlightColor) !important;
}