@import url('../../assets/styles/fonts.css');

.HeaderContainer {
    display: grid;
    grid-template-columns: 40px auto;
    align-items: center;
    font-family: 'Gilroy-SemiBold' !important;
}

.HeaderName {
    display: grid;
    grid-template-columns: auto auto;
    gap: 10px;
    justify-content: space-between;
}

@media screen and (max-width: 768px) {
    .HeaderName {
        grid-template-columns: auto;
    }
}

.HeaderName span {
    color: var(--userPrimaryColor) !important;
    font-family: 'Gilroy-UltraLight' !important;
}

.AutoSave {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px 20px;
    gap: 6px;
    font-size: 16px;
    border-radius: 5px;
    background: #8ECEAE20;
    color: #8ECEAE;
    font-weight: normal;
    font-family: 'Gilroy-Regular' !important;
}

/* FF5555 */
.UnsavedChanges {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px 20px;
    gap: 6px;
    font-size: 16px;
    border-radius: 5px;
    background: #FF555520;
    color: #FF5555;
    font-weight: normal;
    font-family: 'Gilroy-Regular' !important;
}

.Hide {
    display: none;
}