.CreateContact_Button {
    background: var(--userPrimaryColor) !important;
    color: white !important;
    min-width: fit-content;
}

.CreateContact_Button:hover {
    background: var(--userHighlightColor) !important;
    color: var(--userPrimaryColor) !important;
    min-width: fit-content;
}