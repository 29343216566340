.NotificationsBody {
    color: var(--themeFontColor) !important;
}

.NotificationTitle {
    font-weight: bolder;
    font-size: 20px;
    padding: 2% 0%;
}

.NotificationTitleInfo {
    font-weight: bold;
    text-transform: uppercase;
}

.NotificationDetailsBlock {
    box-shadow: 0px 1px 10px 0px rgba(68, 126, 183, 0.25);
    border-radius: 5px;
    background: white;
}

.NotificationGridBlock {
    display: grid;
    grid-gap: 1rem;
    width: 80%;
    margin: auto;
}

.NotificationDetailsGrid {
    display: grid;
    grid-template-columns: repeat(2, 1fr) !important;
    grid-gap: 1rem;
    min-width: fit-content;
}

.InvoicingInstruction {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 1rem;
    margin: auto;
    min-width: fit-content;
    width: 40%;
}

@media (max-width: 1100px) {
    .NotificationGridBlock {
        width: 90%;
    }

    .InvoicingInstruction {
        width: 60%;
    }
}

@media (max-width: 770px) {
    .NotificationDetailsGrid {
        grid-template-columns: repeat(1, 1fr) !important;
    }

    .NotificationGridBlock {
        width: 100%;
    }

    .InvoicingInstruction {
        grid-template-columns: repeat(1, 1fr);
        width: 100%;
    }
}

.InstructionsTitle {
    text-transform: uppercase;
    font-weight: bold;
    padding: 2% 0% 1%;
    font-size: large;
}