.Container {
    background: var(--themePrimaryColor) !important;
    border-radius: 4px !important;
    box-shadow: 0px 1px 10px 0px rgba(68, 126, 183, 0.25) !important;
    padding: 2%;
    max-height: 75vh;
    overflow-y: auto;
    scroll-behavior: smooth;
    margin: 5px;
}

.Container::-webkit-scrollbar {
    width: 5px;
}

.Container::-webkit-scrollbar-track {
    background: transparent;
}

.Container::-webkit-scrollbar-thumb {
    background: darkgray !important;
    border-radius: 10px;
    border: 0px solid #F4F4F4;
}

@media screen and (max-width: 770px) {
    .Container {
        max-height: 73vh !important;
    }
}

.TabWrap {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

@media screen and (max-width: 770px) {
    .TabWrap {
        display: grid;
    }

    .TabWrap_Content {
        display: block !important;
        text-align: left !important;
    }
}

.TabWrap_Content {
    padding-bottom: 12px !important;
    font-weight: bold;
    color: var(--userPrimaryColor) !important;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
}

.Hide {
    display: none;
}

.HyperLink {
    text-decoration: underline !important;
    color: var(--userPrimaryColor) !important;
}