@import url("../../assets/styles/fonts.css");

.Container {
    width: 100%;
    height: max-content;
    height: 100%;
    min-height: 100%;
    background: var(--themePrimaryColor) !important;
    box-shadow: 0px 1px 10px 0px rgba(68, 126, 183, 0.25);
    border-radius: 10px;
    padding: 20px;
    margin: 25px 0px;
}

.InnerContainer {
    display: grid;
    grid-template-rows: auto 168px;
    height: 90%;
}

.FullInnerContainer {
    display: grid;
    grid-template-rows: 212px 200px 168px;
    gap: 20px;
    height: 90%;
}

.Header {
    font-family: 'Gilroy-SemiBold';
    font-size: large;
    text-align: left;
    margin: 5px 0px 25px;
    color: var(--themeFontColor) !important;
}

.Details {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(18rem, 1fr));
    gap: 20px;
}

.Documents_Container {
    width: 100%;
    display: grid;
    grid-template-columns: 35% auto;
    gap: 25px;
}

@media screen and (max-width: 1200px) {
    .Documents_Container {
        grid-template-columns: 100%;
        margin-bottom: 40px;
    }
}

.CanceledAlert {
    margin-bottom: 2%;
    text-align: left;
    font-weight: bold;
}

.HideContainer {
    display: none;
}

.Docs_Header {
    font-family: 'Gilroy-SemiBold';
    font-size: large;
    text-align: left;
    margin: 5px 0px 0px;
}

.Docs_SubHeader {
    font-family: 'Gilroy-UltraLight';
    font-size: small;
    text-align: left;
}

.Docs_List {
    max-height: 630px;
    overflow-y: auto;
    scroll-behavior: smooth;
}

.Docs_List::-webkit-scrollbar {
    width: 8px;
}

.Docs_List::-webkit-scrollbar-track {
    background: var(--themeSecondaryColor) !important;
}

.Docs_List::-webkit-scrollbar-thumb {
    background: var(--userPrimaryColor) !important;
    border-radius: 10px;
    border: 1px solid #F4F4F4;
}

.DocsContainer {
    overflow-y: auto;
    scroll-behavior: smooth;
}

.DocsContainer::-webkit-scrollbar {
    width: 8px;
}

.DocsContainer::-webkit-scrollbar-track {
    background: var(--themeSecondaryColor) !important;
}

.DocsContainer::-webkit-scrollbar-thumb {
    background: var(--userPrimaryColor) !important;
    border-radius: 10px;
    border: 1px solid #F4F4F4;
}

.ExistingDocs_Header {
    display: flex;
    justify-content: space-between;
    text-align: left;
    font-size: larger;
    font-family: 'Gilroy-SemiBold';
    margin: 0% 0% 2% 0%;
}

.listContainer {
    margin: 2% 0%;
    padding-right: 4%;
}

.listContainerGrid {
    display: grid;
    grid-template-columns: 15% 85%;
    border-bottom: 1px solid lightgray;
    padding-bottom: 10px;
}

.listIcon {
    display: flex;
    align-items: center;
    justify-content: center;
}

.listDetails {
    display: grid;
    grid-template-columns: 90% 10%;
}

.fileName {
    color: var(--themeFontColor) !important;
    font-weight: bold;
}

.fileStyle {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.UpdateButton_Container {
    display: flex;
    justify-content: space-evenly;
    align-items: baseline;
    margin-bottom: 0px;
}

.UpdateButton {
    height: 40px;
    /* margin-top: 2%; */
}

.SendButton {
    height: 40px;
    margin-top: 5%;
}

.ModalTitle {
    font-family: 'Gilroy-SemiBold';
    font-size: larger;
    text-align: left;
}

.ModalSubTitle {
    font-family: 'Gilroy-Regular';
    font-size: medium;
    text-align: left;
    margin-bottom: 15px;
}

.Checklist {
    max-height: 350px;
    overflow-y: auto;
    scroll-behavior: smooth;
}

.Checklist::-webkit-scrollbar {
    width: 8px;
}

.Checklist::-webkit-scrollbar-track {
    background: var(--themeSecondaryColor) !important;
}

.Checklist::-webkit-scrollbar-thumb {
    background: var(--userPrimaryColor) !important;
    border-radius: 10px;
    border: 1px solid #F4F4F4;
}

.IndividualCheckbox {
    border-bottom: 2px solid var(--themeSecondaryColor);
    padding: 2% 0%;
}

.checked {
    color: var(--userPrimaryColor) !important;
}

.IndividualCheckbox_AdditionalInfo {
    font-size: 15px;
    color: lightgray;
    font-family: 'Gilroy-UltraLight' !important;
}

.ModalActions {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    margin: 5% 0% 0%;
}

.SubmitButton {
    height: 40px;
    width: 120px;
    box-shadow: 0px 1px 10px 0px rgba(68, 126, 183, 0.25);
}

.SubmitButton:disabled {
    background-color: var(--themeSecondaryColor) !important;
    color: darkgray !important;
}

.CanceButton {
    height: 40px;
    width: 120px;
    box-shadow: 0px 1px 10px 0px rgba(68, 126, 183, 0.25);
    background-color: var(--themePrimaryColor) !important;
    outline: 2px solid var(--userHighlightColor) !important;
    border: none !important;
    color: #FF5555 !important;
    transition: background-color 0.5s ease;
}

.CanceButton:hover {
    color: white !important;
    background-color: #FF5555 !important;
    outline: none !important;
    border: none !important;
}

.Applications_ContainerSent {
    width: 100%;
    height: 65vh;
    color: var(--themeFontColor) !important;
    /* padding: 2%; */
    background: var(--themePrimaryColor) !important;
    border-radius: 5px;
    box-shadow: 0px 1px 10px 0px rgba(68, 126, 183, 0.25);
    display: flex;
    justify-content: center;
    align-items: center;
}

.SendAppContainer {
    margin-bottom: 0px;
}

@media screen and (max-width: 500px) {
    .SendAppContainer {
        margin-bottom: 50px;
    }

    .UpdateButton_Container {
        display: flex;
        justify-content: space-evenly;
        align-items: baseline;
        margin-bottom: 50px;
    }
}

.AddDocumentButton:disabled {
    background-color: var(--themeSecondaryColor) !important;
    color: darkgray !important;
}

.Doc_Header_1 {
    color: var(--themeFontColor) !important;
}

.TitleFlex {
    display: flex;
}

.TitleHeader {
    padding-left: 5px;
}

.SubHeader {
    font-size: 14px;
}

.ModalAction {
    display: flex;
    justify-content: center;
    margin: 5% 0% 2%;
}

.ModalAction>Button {
    height: 40px;
}