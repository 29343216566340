.Calculator:hover .Calculator_BG,
.Calculator_BG_Selected {
    fill: var(--userHighlightColor) !important;
}

.Calculator_Border {
    fill: transparent;
}

.Calculator_Fill,
.Calculator_Border_Selected {
    fill: var(--userPrimaryColor) !important;
}

.Calculator_Stroke {
    stroke: var(--userPrimaryColor) !important;
}