@import url("../../assets//styles/fonts.css");

.Container {
    width: 100%;
    padding: 2%;
    background: var(--themePrimaryColor) !important;
    border-radius: 5px;
    box-shadow: 0px 1px 10px 0px rgba(68, 126, 183, 0.25);
    overflow: auto;
}

.Header {
    font-family: 'Gilroy-SemiBold';
    font-size: large;
    text-align: left;
    margin: 5px 0px 25px;
    color: var(--themeFontColor) !important;
}

.HeaderContent {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
}

.HeaderButtons {
    display: flex;
    gap: 20px;
}

@media screen and (min-width: 811px) and (max-width: 920px) {
    .HeaderContent {
        display: block;
    }

    .HeaderButtons {
        display: flex;
        justify-content: space-between;
    }
}

@media screen and (max-width: 810px) {
    .HeaderContent {
        display: block;
    }

    .HeaderButtons {
        display: grid;
        text-align: left;
    }
}

/* @media screen and (max-width: 768px) {
    .Header_Buttons {
        display: grid;
        gap: 2%;
    }
} */

.TertiaryButton {
    background: #4ECBD7 !important;
    color: #FFFFFF !important;
    width: fit-content;
    display: block;
    height: 40px;
}

.TertiaryButton:hover {
    background: var(--themePrimaryColor) !important;
    border: 1px solid var(--userSecondaryHoverColor) !important;
    color: var(--userSecondaryHoverColor) !important;
}

.Disabled {
    background: #EEEEEE !important;
    color: #CCCCCC !important;
    height: 40px;
}

.Disabled:hover {
    background: #EEEEEE !important;
    color: #CCCCCC !important;
    font-weight: bold !important;
}

.Hide {
    display: none !important;
}

.icon {
    color: var(--themeFontColor) !important;
}

.ReqAmtInfo {
    font-family: 'Gilroy-UltraLight' !important;
    font-size: smaller;
    text-align: left;
}

.PopoverContainer {
    width: 100px;
    padding: 5px;
    margin-top: 10px;
}

.PopoverContainer_List {
    border-radius: inherit;
    display: grid;
    gap: 5px;
    grid-template-columns: 30px auto;
    height: 36px;
    border-radius: 5px;
}

.PopoverContainer_List>div:first-child,
.PopoverContainer_List>span {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
}

.PopoverContainer_List>div {
    display: flex;
    /* justify-content: center; */
    align-items: center;
    height: 100%;
}

.PopoverContainer_List:hover {
    background-color: var(--userHighlightColor) !important;
    cursor: pointer;
}

.Edit {
    color: var(--userPrimaryColor) !important;
    font-weight: bold;
}

.Delete {
    color: red;
    font-weight: bold;
}

.DisabledAction {
    cursor: not-allowed !important;
    color: darkgray !important;
    font-weight: bold;
}

.Tag-Yellow,
.Tag-Green,
.Tag-Cyan,
.Tag-Red,
.Tag-Default {
    font-family: 'Gilroy-Medium' !important;
    border: none;
    font-size: 16px;
    padding: 5px 8px;
}

.Tag-Yellow {
    color: #EDCB00;
    background-color: #EDCB0020;
}

.Tag-Green {
    color: #8ECEAE;
    background-color: #8ECEAE40;
}

.Tag-Cyan {
    color: #6FACB2;
    background-color: #6FACB220;
}

.Tag-Red {
    color: #FF5555;
    background-color: #FF555520;
}

.Tag-Default {
    color: #4ECBD7;
    background-color: #4ECBD720;
}