.Error404Container {
    max-height: 80vh;
    overflow-y: hidden;
}

.DefautMessage {
    height: 80vh;
    overflow-y: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
}

.Header {
    display: flex;
    padding: 5% 5% 0%;
    font-size: x-large;
}