.ListingLayout_Tab {
    display: grid;
    grid-template-columns: 250px 250px 40px 40px;
    justify-content: end;
    align-items: center;
    gap: 10px;
    margin: 0 5px 2%;
    width: 100%;
}

@media screen and (max-width: 700px) {
    .ListingLayout_Tab {
        display: flex;
        flex-direction: column;
    }
}

.ListingLayout_Grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
    gap: 10px;
    max-height: calc(90vh - 305px);
    overflow-y: auto;
    overflow-x: hidden;
    padding-right: 10px;
}

.ListingLayout_Grid::-webkit-scrollbar {
    width: 5px;
}

.ListingLayout_Grid::-webkit-scrollbar-track {
    background: transparent;
}

.ListingLayout_Grid::-webkit-scrollbar-thumb {
    background: darkgray !important;
    border-radius: 10px;
    border: 0px solid #F4F4F4;
}

.ListingLayout_List {
    display: flex;
    flex-direction: column;
    gap: 20px;
    max-height: calc(90vh - 305px);
    overflow-y: auto;
}

.ListingLayout_Footer {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
}

@media screen and (max-width: 900px) {
    .ListingLayout_Footer {
        flex-direction: column;
        align-items: flex-start;
        gap: 10px;
    }

    .ListingLayout_Footer>div:last-child {
        text-align: right;
        width: 100%;
    }
}

.Hide {
    display: none;
}