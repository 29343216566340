.Container {
    min-height: fit-content;
}

.UnreadHeader {
    text-align: left;
    font-weight: bold;
    font-size: 18px;
    color: var(--themeFontColor) !important;
}

.ReadHeader {
    text-align: left;
    font-weight: bold;
    font-size: 18px;
    margin-top: 2%;
    color: var(--themeFontColor) !important;
}

.TabContainer {
    max-height: 65vh;
    overflow-y: auto;
    padding: 0px 20px;
    scroll-behavior: smooth;
    position: relative;
}

.TabContainer::-webkit-scrollbar {
    width: 5px;
}

.TabContainer::-webkit-scrollbar-track {
    background: transparent;
}

.TabContainer::-webkit-scrollbar-thumb {
    background: var(--userHighlightColor) !important;
    border-radius: 10px;
}

.TabContainer::-webkit-scrollbar-thumb:hover {
    background: var(--userPrimaryColor) !important;
}

.CloseIcon {
    color: black;
    font-size: large;
}

.CloseIcon:hover {
    color: white;
}

.TagContainer {
    display: flex;
    justify-content: center;
    gap: 10px;
    background: var(--userHighlightColor) !important;
    padding: 10px 20px;
    border-radius: 200px;
}

.MarkAllAsRead {
    line-height: 1.5;
    padding-top: 14px;
    color: var(--userPrimaryColor) !important;
    text-decoration: underline;
    font-family: 'Gilroy-SemiBold' !important;
    font-size: 17px;
    cursor: pointer;
}

.MarkAllAsRead_Disabled {
    line-height: 1.5;
    padding-top: 14px;
    color: #CCCCCC !important;
    text-decoration: underline;
    font-family: 'Gilroy-SemiBold' !important;
    font-size: 17px;
    cursor: not-allowed;
}

.MarkAllAsRead:not(:disabled):hover {
    color: #4ECBD7 !important;
}

.Hide {
    display: none;
}