.cardLabel {
    font-size: 1.5rem;
    font-weight: bold;
    font-family: 'Gilroy-Regular';
    color: var(--themeFontColor) !important;
}

.table {
    font-family: 'Gilroy-Regular';
}

.Label {
    height: 10%;
}

.Table {
    height: 90%;
}

.Table-List {
    max-height: 10vh;
}

.orangeTag {
    width: 60%;
    min-width: fit-content;
    white-space: nowrap;
    color: #d46b08;
    background-color: #fff7e6;
    text-align: center;
    border: none;
    padding: 3px 10px;
    border-radius: 5px;
}

.greenTag {
    width: 60%;
    min-width: fit-content;
    white-space: nowrap;
    color: var(--userSecondaryColor);
    text-align: center;
    background-color: var(--userSecondaryColor)40;
    border: none;
    padding: 3px 10px;
    border-radius: 5px;
}

.GreenTags {
    color: var(--userSecondaryColor) !important;
    background: #8ECEAE40 !important;
}

.NeutralTags {
    color: #4ECBD7 !important;
    background: #4ECBD740 !important;
}

.RedTags {
    color: #FF5555 !important;
    background: #FF555540 !important;
}

.Mobile_Content {
    margin: 10px 0px;
    font-family: 'Gilroy-Regular' !important;
    width: 100%;
    max-height: 350px;
    overflow: hidden auto;
    padding-right: 10px;
    scroll-behavior: smooth;
}

.Mobile_Content::-webkit-scrollbar {
    width: 5px;
}

.Mobile_Content::-webkit-scrollbar-track {
    background: transparent;
}

.Mobile_Content::-webkit-scrollbar-thumb {
    background: darkgray !important;
    border-radius: 10px;
    border: 0px solid #F4F4F4;
}

.Mobile_Content>div {
    display: grid;
    gap: 5px;
    padding: 10px 0px;
    text-align: left;
}

.Mobile_Content>div:hover {
    background-color: var(--userHighlightColor) !important;
    cursor: pointer;
}

.Mobile_Content>div:not(:last-child) {
    border-bottom: 1px solid #CCCCCC;
}

.Mobile_Content>div>div {
    display: grid;
    grid-template-columns: 33% auto 33%;
    gap: 5px
}

.Mobile_Content>div>div:is(:last-child) {
    align-items: end;
}

.Mobile_Content>div>div:is(:first-child)>div:first-child {
    font-family: 'Gilroy-SemiBold';
}

.Mobile_Content>div>div:is(:first-child)>div:last-child {
    text-align: right;
}

.Mobile_Content>div>div:is(:last-child)>div>div:first-child {
    color: #CCCCCC;
    font-size: 13px;
}

.Mobile_Content>div>div:is(:last-child)>div:last-child {
    text-align: right;
}

.Mobile_Content_Asset {
    color: var(--userPrimaryColor) !important;
}