@import url('../../assets/styles/fonts.css');

.Sider {
    height: 100vh !important;
    background-color: var(--themePrimaryColor) !important;
    padding: 20px 0px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.Sider_Container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    text-align: start;
}

.Default_Logo_Container {
    display: flex;
    justify-content: center;
    align-items: center;
}

.User_Logo_Container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 15px 0px 30px;
    min-height: 130px;
    max-height: 130px;
}

.Logo_Desktop {
    width: 80%;
    cursor: pointer;
}

.User_Logo_Desktop {
    width: 80%;
    cursor: pointer;
    object-fit: none;
}

.Logo_Desktop_Collapsed {
    width: 30px;
    height: 42px;
    object-fit: cover;
    object-position: 0 100%;
    cursor: pointer;
    animation: contract 0.3s ease-in-out forwards;
}

@keyframes contract {
    0% {
        width: 100%;
        height: 42px;
        object-fit: cover;
        object-position: 0 100%;
    }

    100% {
        width: 50px;
        height: 42px;
        object-fit: cover;
        object-position: 0 100%;
    }
}

.Logo_Desktop_Expanded {
    cursor: pointer;
    animation: expand 0.3s ease-in-out forwards;
}

@keyframes expand {
    0% {
        width: 50px;
        height: 42px;
        object-fit: cover;
        object-position: 0 100%;
    }

    100% {
        width: 100%;
        height: 45px;
        object-fit: cover;
        object-position: 0 100%;
    }
}

.User_Mobile_Logo {
    object-fit: none;
}

.SideNav_Divider {
    border-color: var(--themeSecondaryColor) !important;
    margin: 5px 0px !important;
}

.Icon_Menu {
    display: flex !important;
    justify-content: center;
    height: 40px;
    color: var(--userPrimaryColor) !important;
    font-size: 20px;
}

.Icon_More {
    display: flex !important;
    padding-left: 25px;
    height: 40px;
    color: var(--userPrimaryColor) !important;
    font-size: 20px;
}

.SideNav_HeaderName {
    display: flex;
    align-items: center;
    height: 40px;
    padding-left: 25px;
    text-transform: uppercase;
    color: var(--userHighlightColor) !important;
}

.Hide {
    display: none;
}

.Invisible {
    visibility: hidden;
    height: 40px;
}

.SideNav_Item {
    display: grid;
    align-items: center;
    grid-template-columns: 25px 35px auto;
    gap: 5px;
    height: 50px;
    border-radius: 5px;
    color: var(--userPrimaryColor) !important;
    font-size: large;
    cursor: pointer;
    overflow: hidden;
    transition: all 0.3s;
    font-weight: bold;
}

.Collapsed {
    grid-template-columns: 0px auto !important;
    align-items: center !important;
    justify-content: center !important;
}

.SideNav_Item:hover {
    background-color: var(--userHighlightColor) !important;
    fill-opacity: 0.5;
    color: var(--userPrimaryColor) !important;
}

.SideNav_Item_Selected {
    background-color: var(--userHighlightColor) !important;
    border-right: 5px solid var(--userPrimaryColor) !important;
}

.SideNav_Item_Selected_Collapsed {
    background-color: var(--userHighlightColor) !important;
    border-bottom: 5px solid var(--userPrimaryColor) !important;
}

.Copyright {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    position: absolute;
    bottom: 25px;
    color: var(--userPrimaryColor) !important;
    font-size: 13px;
}