.Applications {
    color: var(--themeFontColor) !important;
    text-align: left;
}

.Header {
    font-weight: bold;
    font-size: 1.5rem;
    margin-bottom: 5px;
    height: 10%;
    margin: 0px 0px 0px 2px;
}

.List {
    height: 90%;
}

.AppCards {
    width: 100%;
    overflow-x: auto;
    display: flex;
    padding: 2% 0% 0%;
}

.AppCards::-webkit-scrollbar {
    height: 5px;
}

.AppCards::-webkit-scrollbar-track {
    background: transparent;
}

.AppCards::-webkit-scrollbar-thumb {
    background-color: var(--userPrimaryColor) !important;
    border-radius: 10px;
    /* border: 3px solid transparent; */
}

.IndividualApp {
    width: 33%;
    margin: 0% 2%;
    padding: 20px;
    background: var(--themePrimaryColor) !important;
    border-radius: 4px !important;
    box-shadow: 0px 1px 10px 0px rgba(68, 126, 183, 0.25);
    min-width: fit-content;
}

.IndividualApp:first-child {
    margin-left: 0%;
}

.IndividualApp:last-child {
    margin-right: 0%;
}

.IndividualApp_Content {
    height: 150px !important;
}

.IndividualApp_Business {
    font-size: large;
    font-weight: bold;
    display: flex;
    justify-content: center;
    padding-bottom: 5%;
}

.Center {
    display: flex;
    justify-content: center;
    align-items: center;
}

.EmptyContainer {
    display: grid;
    grid-template-columns: 60px auto;
    width: 60%;
    min-width: fit-content;
    margin: auto;
}

.Hide {
    display: none;
}

.Content{
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: max-content;
    height: calc(100% - 40px);
    margin: auto;
}