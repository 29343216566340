.profileContainer {
    /* box-shadow: 0px 1px 10px 0px rgba(68, 126, 183, 0.25);
    border-radius: 5px;
    background: var(--themePrimaryColor) !important; */
}

.profileInnerContainer {
    /* margin: 2%; */
    /* padding: 4% 0%; */
}

.contactsHeader {
    display: grid;
    grid-template-columns: 80% 20%;
}

.contactsSubHeader {
    text-align: left;
    min-width: fit-content;
}

.headerStyle {
    font-size: 18px;
    font-weight: bold;
    text-align: left;
    color: var(--themeFontColor) !important;
}

.warning {
    font-size: 18px;
    font-weight: bold;
    color: red;
}

.alertText {
    color: grey;
}

.contactCreate {
    min-width: fit-content;
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

@media (max-width: 770px) {
    .contactsHeader {
        display: grid;
        grid-template-columns: 60% 40%;
    }
}