.NotificationIcon {
    height: 60px;
}

.Content {
    border-radius: 0px 0px 10px 10px !important;
}

.DescriptionContainer {
    display: flex;
    position: relative;
    left: -10px;
    grid-gap: 5%;
}

.DescriptionHeader {
    font-weight: bolder;
    font-size: 16px;
    color: var(--userPrimaryColor) !important;
}