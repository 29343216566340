.Container {
    color: var(--themeFontColor) !important;
    background: var(--themePrimaryColor) !important;
    border-radius: 10px;
    box-shadow: 0px 1px 10px 0px rgba(68, 126, 183, 0.25);
    padding: 15px;
}

.IndividualItem {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    height: 50px;
    gap: 15px;
    border-bottom: 1px solid #CCCCCC !important;
}

.IndividualItem:hover {
    background: var(--userHighlightColor) !important;
    cursor: pointer;
}

.UrlStyling {
    text-decoration: underline;
    color: var(--themeFontColor) !important;
}

.Assistance {
    margin-top: 2%;
    color: var(--themeFontColor) !important;
    /* font-weight: bold; */
}