.profileContainer {
    background: var(--themePrimaryColor) !important;
}

.profileInnerContainer {
    width: 80%;
}

.profileContainer_Header {
    font-size: 18px;
    font-weight: bold;
    text-align: left;
    color: var(--themeFontColor) !important;
}

.businessDetails_Layout {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 1rem;
    min-width: fit-content;
}

@media (max-width: 770px) {
    .businessDetails_Layout {
        grid-template-columns: repeat(1, 1fr);
    }

    .profileInnerContainer {
        width: 100%;
    }
}

.SaveButton {
    background: var(--userPrimaryColor) !important;
    color: white;
}

.SaveButton:hover {
    background: var(--userHighlightColor) !important;
    color: var(--userPrimaryColor) !important;
}