@import url("../../assets/styles/fonts.css");

.NotificationDetailsBlock {
    box-shadow: 0px 1px 10px 0px rgba(68, 126, 183, 0.25);
    border-radius: 5px;
    background: var(--themePrimaryColor) !important;
    padding: 2%;
    text-align: left;
}

.NotificationDetailsTitle {
    /* text-transform: uppercase; */
    font-weight: bolder;
    padding: 2% 0%;
    font-family: "Gilroy-SemiBold" !important;
}

.NotificationRow {
    padding: 1% 0%;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 3rem;
}

@media (max-width: 700px) {
    .NotificationRow {
        padding: 5% 0%;
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        grid-gap: 1rem;
    }
}

.NewDetails {
    background-color: var(--userHighlightColor) !important;
    border-radius: 5px;
    padding: 5px 15px;
}