@import url("../../assets/styles/fonts.css");

.Container {
    display: grid;
    grid-template-columns: 40% 60%;
    height: 100vh;
    width: 100%;
    background-color: #f4f4f4;
    font-family: 'Gilroy-Regular' !important;
}

.emptyContainer {
    background-image: url(https://lanternpublicstaticfiles.blob.core.windows.net/main/PasswordReset1.svg);
    background-repeat: repeat-y;
    background-size: auto;
    width: 100%;
    height: 100%;
    background-position: center center;
}

@media (max-width: 600px) {
    .Container {
        grid-template-columns: 100%;
    }

    .emptyContainer {
        display: none;
    }
}

.rightLayout {
    height: 100vh;
    display: flex;
    width: 80%;
    margin: auto;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.rightLayout h1 {
    font-size: 24px;
    margin-bottom: 10px;
}

.rightLayout p {
    font-size: 16px;
    margin-bottom: 20px;
}

.emailInput {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 20px;
}

.emailInput label {
    font-size: 16px;
    margin-bottom: 5px;
}

.resetPasswordButton {
    margin: 2%;
    width: 20%;
    height: 35px;
    min-width: fit-content;
    background: #8ECEAE;
    font-weight: bold;
}

.resetPasswordButton:hover {
    background: var(--userSecondaryHoverColor) !important;
    color: white !important;
}

.sendCodeButton {
    margin: 2%;
    width: 20%;
    height: 35px;
    min-width: fit-content;
    background: #447EB7;
    font-weight: bold;
}

.sendCodeButton:hover {
    background: #CFDCE9 !important;
    color: #447EB7 !important;
}

.backToLoginButton {
    margin: 2%;
    width: 20%;
    height: 35px;
    min-width: fit-content;
    background: #EDCB00;
    color: black;
    font-weight: bold;
}

.backToLoginButton:hover {
    background: white !important;
    color: #EDCB00 !important;
    border: 2px solid #EDBC00;
}

.divider {
    color: black
}