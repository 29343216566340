@import url("../../assets/styles/fonts.css");

.AddUser_Container {
    display: flex;
    justify-content: center;
}

.AddUser_Title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-family: "Gilroy-SemiBold" !important;
    font-size: 22px;
}

.AddUser_Title>div:last-child {
    padding: 2px 6px;
    border-radius: 5px;
}

.AddUser_Title>div:last-child:hover {
    background: var(--themeSecondaryColor) !important;
    cursor: pointer;
}

.AddUser_Button {
    height: 40px;
    font-size: 18px;
}