@import url("../../assets/styles/fonts.css");

.HeaderContainer {
    display: flex;
    justify-content: space-between;
}

.Header {
    font-size: 24px;
    font-family: "Gilroy-SemiBold" !important;
}

.FilterTypeContainer {
    width: fit-content !important;
    padding: 10px;
}

.FilterGrid {
    display: grid;
    grid-template-columns: auto auto;
    gap: 10px;
}

@media screen and (max-width: 768px) {
    .FilterGrid {
        grid-template-columns: auto;
        gap: 10px;
    }
}

.ClearAll {
    display: flex;
    justify-content: flex-end;
    font-family: 'Gilroy-SemiBold' !important;
    text-decoration: underline;
}

.ClearAll:hover {
    cursor: pointer;
    color: #4ECBD7 !important;
}

.ClearAll-Disabled {
    display: flex;
    justify-content: flex-end;
    font-family: 'Gilroy-SemiBold' !important;
    color: #CCCCCC !important;
    text-decoration: underline;
}