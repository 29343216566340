.Container {
    max-height: 90vh;
    padding: 1% 0%;
    color: var(--themeFontColor) !important;
    background: var(--themePrimaryColor) !important;
    line-height: 35px;
    overflow-y: scroll;
}
.Container::-webkit-scrollbar {
    width: 5px;
    height: 5px;
}
.Container::-webkit-scrollbar-track {
    background: var(--themeSecondaryColor) !important;
    border-radius: 5px;
}
.Container::-webkit-scrollbar-thumb {
    background-color: var(--userPrimaryColor) !important;
    border-radius: 20px;
}
.Header {
    font-size: 24px;
    font-weight: bold;
}
.Close {
    transition: all 0.2s ease-in-out;
    padding: 0px 10px 0px 0px;
}
.Close:hover {
    cursor: pointer;
    transform: scale(1.2);
    transition: all 0.2s ease-in-out;
}
.SubHeader {
    font-size: 16px;
}
.SelectedAssetRow {
    display: flex;
    gap: 2%;
    background-color: var(--userHighlightColor) !important;
    margin: 1% 0%;
}
.ActionButtons {
    display: flex;
    justify-content: space-between;
    padding-top: 5%;
}
.CancelButton {
    color: #FFFFFF !important;
    background: #FF5555 !important;
    min-width: fit-content;
    width: 150px;
    height: 40px;
    transition: all 0.2s ease-in-out;
}
.CancelButton:hover {
    color: #FF5555 !important;
    background: #FFFFFF !important;
    border: 2px solid #FF5555 !important;
    transition: all 0.2s ease-in-out;
}
.Button {
    width: 150px;
    height: 40px;
}
.Button:disabled {
    background-color: var(--themeSecondaryColor) !important;
    color: darkgray !important;
}
.Header_Row {
    display: flex;
    justify-content: flex-start;
    font-weight: bold;
    font-size: 22px;
    padding-top: 2%;
}
.TransferredAssetRow {
    display: flex;
    gap: 2%;
    margin: 1% 0%;
    font-weight: bold;
    border-bottom: 1px solid #CCCCCC !important;
}
.TransferredApprovalRow {
    display: grid;
    grid-template-columns: 4% 80% 16%;
    margin: 1% 0%;
    border-bottom: 1px solid #CCCCCC !important;
    font-weight: bold;
}
@media(max-width: 600px) {
    .TransferredApprovalRow {
        display: flex;
        align-items: center;
        margin: 1% 0%;
        border-bottom: 1px solid #CCCCCC !important;
        font-weight: bold;
    }
}
.ViewButton {
    border-radius: 15px !important;
    width: fit-content;
}
.Center {
    display: flex;
    justify-content: center;
}
.BackCenter {
    display: flex;
    justify-content: center;
    padding-top: 5%;
}
.BackButton {
    height: 40px !important;
}
.GreenTags {
    color: var(--userSecondaryColor) !important;
    background: #8ECEAE40 !important;
}
.NeutralTags {
    color: #4ECBD7 !important;
    background: #4ECBD740 !important;
}
.RedTags {
    color: #FF5555 !important;
    background: #FF555540 !important;
}