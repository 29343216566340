.profileContainer {
    box-shadow: 0px 1px 10px 0px rgba(68, 126, 183, 0.25);
    border-radius: 5px;
    background: var(--themePrimaryColor) !important;
}

.profileContent {
    display: grid;
    grid-template-columns: 170px auto;
    gap: 15px;
    text-align: start;
}

.profileDivision {
    display: flex;
    justify-content: center;
}

.profileButtons {
    display: flex;
    justify-content: space-around;
    gap: 10px;
    padding-top: 5%;
}

.profileDetails {
    margin: 4% 0% 0% 3%;
}

.profileName {
    font-weight: 700;
    font-size: 18px;
    color: var(--themeFontColor) !important;
}

.modalHeader {
    font-weight: bold;
    text-align: center;
    font-size: 20px;
}

@media (max-width: 770px) {
    .profileContent {
        display: grid;
        grid-template-columns: 35% 65%;
        text-align: start;
    }

    .profileButtons {
        display: grid;
        grid-gap: 8%;
    }

    .profileDetails {
        margin: 6% 0% 0% 5%;
    }
}

.deleteButton {
    background: #FF5555;
    color: white;
    width: 100px;
    min-width: fit-content;
}

.deleteButton:hover {
    background: transparent !important;
    /* background: white !important; */
    color: #FF5555 !important;
    border: 1px solid #FF5555 !important;
    width: 100px !important;
    min-width: fit-content !important;
    transition: ease-in-out 0.5s;
}

.deleteButton_Small {
    background: #FF5555;
    color: white;
    font-size: 11px !important;
    width: 55px;
}

.deleteButton_Small:hover {
    background: transparent !important;
    color: #FF5555 !important;
    border: 1px solid #FF5555 !important;
    font-size: 11px !important;
}

.primaryButton {
    background: var(--userPrimaryColor) !important;
    color: white;
    font-size: 11px !important;
    width: 55px;
}

.primaryButton:hover {
    background: var(--userHighlightColor) !important;
    color: var(--userPrimaryColor) !important;
    font-size: 11px !important;
}

.profileAvatar {
    background: var(--userHighlightColor) !important;
}