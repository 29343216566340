.cardLabel {
    font-size: 1.5rem;
    font-weight: bold;
    font-family: 'Gilroy-Regular';
}

.task_list {
    font-family: 'Gilroy-Regular';
    font-size: 16px;
    line-height: 20.8px;
    margin-left: 1rem;
}

.task_list_done {
    font-family: 'Gilroy-Regular';
    font-size: 16px;
    line-height: 20.8px;
    margin-left: 1rem;
    opacity: 0.7;
}

.add_task {
    color: var(--userPrimaryColor) !important;
    font-family: 'Gilroy-Regular';
    font-size: 16px;
    line-height: 20.8px;
    font-weight: 700;
    /* margin-left: 1rem; */
}

.tab {
    text-align: start;
    font-family: 'Gilroy-Regular';
    overflow-y: auto;
    overflow-x: hidden;
}

.tab:hover {
    overflow-y: auto;
}


.tab::-webkit-scrollbar {
    width: 5px;
    /* width of the entire scrollbar */
}

.tab::-webkit-scrollbar-track {
    background: var(--themePrimaryColor) !important;
    /* color of the tracking area */
}

.tab::-webkit-scrollbar-thumb {
    background-color: var(--userPrimaryColor);
    /* color of the scroll thumb */
    border-radius: 20px;
    /* roundness of the scroll thumb */
    border: 2px solid #f5f5f5;
    /* creates padding around scroll thumb */
}

.inputAddTask {
    outline: 0;
    border-width: 0 0 0px;
    border-color: #CCCCCC;
    margin-left: 1rem;
    font-family: 'Gilroy-Regular';
    font-size: 16px;
    width: 78%;
}

.inputAddTask:focus {
    color: "black";
}

.inputAddTask:focus {
    border-color: #5D5D85;

}

.inputAddTask:hover {
    border-color: #5D5D85;

}

.Hide {
    display: none !important;
}

.PaperRuler {
    width: 100%;
    height: 35px;
    border-bottom: 2px solid var(--themeSecondaryColor) !important;
    margin-top: 3px;
}

@media screen and (max-width: 700px) {
    .PaperRuler {
        display: none;
    }
}

.MyTask-Row {
    margin-top: 1rem;
    border-bottom: 2px solid var(--themeSecondaryColor) !important;
}

.AddNewTask {
    margin-top: 1rem;
    border-bottom: 2px solid var(--themeSecondaryColor) !important;
    padding-bottom: 2px;
}