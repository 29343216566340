.Container {
    display: grid;
    grid-template-columns: 30% 70%;
    gap: 1%;
    color: var(--themeFontColor) !important;
    padding: 2%;
    min-height: 40vh;
}

.Metrics_Container {
    display: grid;
    gap: auto;
}

.Metrics_Inner {
    display: grid;
    grid-template-columns: 60px auto;
    gap: 5%;
    text-align: left;
}

.Metrics_Icon {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding-right: 5px;
}

.Metrics_Data {
    white-space: nowrap;
    display: grid;
    width: 100%;
}

.Metrics_Value {
    display: flex;
    align-items: flex-end;
    font-size: 22px;
    font-weight: bold;
}

.Metrics_Label {
    display: flex;
    align-items: flex-start;
    color: #898989 !important;
    font-size: medium;
}

@media screen and (max-width: 1485px) {
    .Metrics_Container {
        display: grid;
        gap: 20px;
        grid-template-columns: auto auto auto;
        width: 100%;
    }

    .Container {
        display: grid;
        grid-template-columns: 1fr;
        gap: 20px;
    }
}

@media screen and (max-width: 1450px) {
    .Metrics_Container {
        display: grid;
        gap: 20px;
        grid-template-columns: auto auto;
        width: 100%;
    }
}

@media screen and (max-width: 1260px) {
    .Metrics_Container {
        display: grid;
        gap: 20px;
        grid-template-columns: auto;
        width: 100%;
    }
}

@media screen and (max-width: 700px) {
    .Container {
        display: grid;
        grid-template-columns: 1fr;
        gap: 5%;
        padding-bottom: 30px;
    }

    .Metrics_Container {
        display: grid;
        margin: auto;
        width: 100%;
        gap: 6%;
    }

    .Metrics_Inner {
        display: grid;
        grid-template-columns: 100px auto;
        gap: 10%;
        text-align: left;
    }

}

.Empty {
    display: grid;
    margin: auto;
}

.Hide {
    display: none;
}