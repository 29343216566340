@import url("../../assets/styles/fonts.css");

.UsersList_ButtonBlock {
    display: flex;
    justify-content: flex-end;
}

.Column_Status {
    display: flex;
    flex-wrap: nowrap;
}

.Column_Status_Icon {
    padding-left: 5%;
    font-size: 18px;
}

.Column_Status_Star_Filled {
    color: gold;
    font-size: 24px;
}

.Column_Username_Primary {
    white-space: nowrap;
    font-family: "Gilroy-SemiBold" !important;
}

.Column_Username {
    white-space: nowrap;
}