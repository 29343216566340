.backgroundFill {
    fill: transparent !important;
}

.ListIcon:hover .backgroundFill {
    fill: var(--userHighlightColor) !important;
}

.backgroundFill_selected {
    fill: var(--userHighlightColor) !important;
}

.selectedBorder {
    stroke: var(--userPrimaryColor) !important;
}

.unselectedBorder {
    stroke: transparent !important;
}

.ListIcon:hover .unselectedBorder {
    stroke: var(--userHighlightColor) !important;
}

.selectedBlocks {
    stroke: var(--userPrimaryColor) !important;
    fill: var(--userPrimaryColor) !important;
}

.unselectedBlocks {
    stroke: var(--userPrimaryColor) !important;
    fill: transparent !important;
}

.ListIcon:hover .unselectedBlocks {
    stroke: var(--userPrimaryColor) !important;
    fill: var(--userPrimaryColor) !important;
}

.Lines {
    fill: var(--userPrimaryColor) !important;
}