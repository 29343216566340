@import url("./fonts.css");

body {
    margin: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
        monospace;
}

.clickable-row:hover {
    cursor: pointer;
}

/* Ant-design CSS Override */
:where(.css-dev-only-do-not-override-1me4733).ant-steps.ant-steps-navigation .ant-steps-item.ant-steps-item-active::before {
    inset-inline-start: 0;
    width: 100%;
}

:where(.css-dev-only-do-not-override-ph9edi).ant-steps.ant-steps-navigation .ant-steps-item::before {
    position: absolute;
    bottom: 0;
    inset-inline-start: 50%;
    display: inline-block;
    width: 0;
    height: 2px;
    transition: width 0.3s, inset-inline-start 0.3s;
    transition-timing-function: ease-out;
    content: "";
}

.ant-checkbox-group-item {
    margin-inline-start: 8px;
}

.ant-float-btn-primary .ant-float-btn-body {
    transition: background-color 0.2s;
    background-color: var(--userPrimaryColor) !important;
}

.ant-btn-primary:hover {
    color: var(--userPrimaryColor) !important;
    background-color: var(--userHighlightColor) !important;
}

.ant-btn-primary:disabled {
    color: #CCCCCC !important;
    background-color: #EEEEEE !important;
    border: none;
}

.ant-btn-primary:disabled:hover {
    color: #CCCCCC !important;
    background-color: #EEEEEE !important;
    border: none;
    outline: 1px solid #CCCCCC !important;
}

:where(.css-dev-only-do-not-override-ph9edi).ant-tabs .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    font-weight: 600;
    text-shadow: 0 0 0.25px currentcolor;
}

.ant-tabs .ant-tabs-tab {
    font-family: 'Gilroy-SemiBold' !important;
}

.ant-tabs .ant-tabs-tab:hover {
    color: var(--userHighlightColor) !important;
}

:where(.css-dev-only-do-not-override-ph9edi).ant-tabs .ant-tabs-ink-bar {
    position: absolute;
    pointer-events: none;
}

.ant-menu-title-content {
    padding-left: 25px;
}

.ant-menu-inline .ant-menu-item,
.ant-menu-vertical .ant-menu-item {
    margin-block: 0px !important;
    border-radius: 0px !important;
}

.ant-menu-item-selected {
    background-color: var(--userHighlightColor) !important;
    border-left: 4px solid var(--userPrimaryColor) !important;
    color: black !important;
    border-radius: 0px !important;
    font-weight: bold !important;
}

.ant-menu-item-selected>.ant-menu-title-content {
    font-family: 'Gilroy-SemiBold', sans-serif !important;
}

.ant-menu-item:hover {
    background-color: var(--userHighlightColor) !important;
    border-radius: 0px !important;
    color: black;
    font-weight: bolder !important;
}

:where(.css-dev-only-do-not-override-16fkrc5).ant-menu-inline.ant-menu-root .ant-menu-item>.ant-menu-title-content,
:where(.css-dev-only-do-not-override-16fkrc5).ant-menu-inline.ant-menu-root .ant-menu-submenu-title>.ant-menu-title-content {
    flex: auto;
    min-width: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    color: white !important;
}

.ant-color-picker-trigger {
    border-radius: 50% !important;
    border: 0px;
    width: 55px !important;
    height: 55px !important;
    transition: all 0.3s !important;
}

.ant-color-picker-color-block {
    width: 45px !important;
    height: 45px !important;
}

.ant-color-picker-trigger:hover {
    border: 2px solid #CCCCCC;
}

.ant-color-picker-color-block {
    border-radius: 50% !important;
}

.ant-select-disabled:where(.css-dev-only-do-not-override-jt9twf).ant-select:not(.ant-select-customize-input) .ant-select-selector {
    color: black;
    background: white;
    cursor: not-allowed;
}

:where(.css-dev-only-do-not-override-jt9twf).ant-input-disabled,
:where(.css-dev-only-do-not-override-jt9twf).ant-input[disabled] {
    color: black;
    background-color: rgba(0, 0, 0, 0.04) !important;
    border-color: #d9d9d9;
    box-shadow: none;
    cursor: not-allowed;
    opacity: 1;
}

#requirementsDragger {

    width: 100%;
    height: 100%;

    .ant-upload-wrapper .ant-upload-drag {
        position: relative;
        width: 100%;
        height: 100%;
        text-align: center;
        background: var(--themePrimaryColor) !important;
        border: 2px dashed var(--userHighlightColor) !important;
        border-radius: 8px;
        cursor: pointer;
        transition: border-color 0.3s;
    }

    .ant-upload-wrapper .ant-upload-drag:hover {
        border-color: var(--userPrimaryColor) !important;
    }
}

#SettingsDragger {
    .ant-upload-wrapper .ant-upload-drag {
        position: relative;
        width: 100%;
        height: 100%;
        text-align: center;
        background: var(--themePrimaryColor) !important;
        border: 2px dashed var(--userHighlightColor) !important;
        border-radius: 8px;
        cursor: pointer;
        transition: border-color 0.3s;
    }
}

#requirementsPreFunding {

    width: 100%;
    height: 35vh;

    .ant-upload-wrapper .ant-upload-drag {
        position: relative;
        width: 100%;
        height: 100%;
        text-align: center;
        background: #F7F7F7 !important;
        border: 2px dashed #D9D9D9 !important;
        border-radius: 8px;
        cursor: pointer;
        transition: border-color 0.3s;
    }
}

.test>.ant-select>.ant-select-selector {
    height: 50px !important;
}


.test>.ant-select>.ant-select-selector>.ant-select-selection-item {
    line-height: 50px !important;
}

.modalTest>.ant-modal>.ant-modal-content {
    position: absolute;
    left: 0px;
    top: 0px;
    transform: (-50%, -50%);
    background-color: #ffffff;
    background-clip: padding-box;
    border: 0;
    border-radius: 8px;
    box-shadow: 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
    pointer-events: auto;
    padding: 20px 24px;
    margin: 5% !important;
}

.ant-popover .ant-popover-inner {
    padding: 0px !important;
}

.ant-popover .ant-popover-title {
    margin-bottom: 0px !important;
}

#qqcOutput {
    .ant-input[disabled] {
        text-align: center !important;
        font-weight: bold;
    }
}

.ant-table-wrapper .ant-table-thead>tr>th {
    font-family: 'Gilroy-Medium' !important;
}

.ant-table-body {
    text-align: start;
    font-family: 'Gilroy-Regular';
}

.ant-table-tbody>tr.ant-table-row:hover>td {
    background-color: var(--userHighlightColor) !important;
    color: black !important;
}

.ant-table-body::-webkit-scrollbar {
    width: 5px;
    height: 5px;
}

.ant-table-body::-webkit-scrollbar-track {
    background: transparent;
}

.ant-table-body::-webkit-scrollbar-thumb {
    background-color: darkgray;
    border-radius: 10px;
}

.ant-drawer .ant-drawer-title {
    font-family: 'Gilroy-SemiBold' !important;
}

.ant-drawer .ant-drawer-body {
    text-align: left !important;
}

#drawdown {
    .ant-steps.ant-steps-dot .ant-steps-item-icon {
        width: 24px;
        height: 24px;
        margin-inline-start: 60px;
        padding-inline-end: 0;
        line-height: 8px;
        background: transparent;
        border: 0;
    }

    @media (min-width: 1200px) {
        .ant-steps.ant-steps-dot .ant-steps-item-tail {
            top: 9.5px;
            width: 100%;
            margin-top: 0;
            margin-bottom: 0;
            margin-inline: 70px 0;
            padding: 0;
        }

        .ant-steps.ant-steps-dot .ant-steps-item-tail::after {
            width: calc(100% - 21px);
            height: 4px;
            margin-inline-start: 12px;
        }
    }

    @media (max-width: 1200px) {
        .ant-steps.ant-steps-vertical.ant-steps-dot .ant-steps-item>.ant-steps-item-container>.ant-steps-item-tail::after {
            margin-inline-start: 70.5px;
        }

        .ant-steps.ant-steps-vertical>.ant-steps-item>.ant-steps-item-container>.ant-steps-item-tail::after {
            width: 2px;
            height: 100%;
        }

        .ant-steps.ant-steps-vertical.ant-steps-dot .ant-steps-item-icon {
            margin-top: 5px;
        }
    }

    .ant-steps .ant-steps-item-process .ant-steps-item-icon>.ant-steps-icon .ant-steps-icon-dot {
        border: 2px solid darkgray;
    }

    .ant-steps .ant-steps-item-wait .ant-steps-item-icon>.ant-steps-icon .ant-steps-icon-dot {
        color: rgba(0, 0, 0, 0.65) !important;
        background: lightgray;
        border: 2px solid darkgray;
    }

    .ant-steps .ant-steps-item-error .ant-steps-item-icon>.ant-steps-icon .ant-steps-icon-dot {
        background: transparent;
        border: 2px solid #FF5555;
    }

    .ant-steps-item-title {
        font-size: medium;
    }

    .ant-steps-item-description {
        font-size: small;
    }
}

.ant-form-item .ant-form-item-label {
    display: inline-block;
    flex-grow: 0;
    overflow: hidden;
    white-space: nowrap;
    text-align: start !important;
    vertical-align: middle;
    font-family: 'Gilroy-Medium' !important;
}

#ContactInputField {
    @media (max-width: 500px) {
        .ant-form-item .ant-form-item-label {
            width: 100%;
        }
    }
}

.ant-switch .ant-switch-handle::before {
    background-color: var(--themePrimaryColor, #ffffff) !important;
}

.ant-switch:hover {
    background: var(--userHighlightColor, #CFDCE9) !important;
}

.ant-pagination .ant-pagination-item-active {
    font-weight: 600;
    background-color: var(--userSecondaryColor) !important;
    border-color: var(--userSecondaryColor) !important;
}

.ant-pagination {
    background-color: transparent !important;
    display: flex !important;
    justify-content: center !important;
}

.ant-pagination .ant-pagination-item a {
    display: block;
    padding: 0 6px;
    color: #898989 !important;
}

.ant-pagination .ant-pagination-item-active a {
    color: var(--themePrimaryColor) !important;
}

.ant-pagination .ant-pagination-item:hover {
    border: 1px solid var(--userSecondaryColor) !important;
}

.ant-pagination .ant-pagination-disabled .ant-pagination-item-link,
.ant-pagination .ant-pagination-disabled:hover .ant-pagination-item-link {
    color: #EEEEEE !important;
    cursor: not-allowed;
}

.ant-form-item .ant-form-item-label>label {
    height: 0px !important;
}

#homeTab {

    .ant-tabs>.ant-tabs-nav .ant-tabs-nav-wrap,
    .ant-tabs>div>.ant-tabs-nav .ant-tabs-nav-wrap {
        position: relative;
        display: flex;
        flex: auto;
        align-self: stretch;
        overflow: hidden;
        white-space: nowrap;
        transform: translate(0);
        justify-content: flex-end;
        font-weight: bold;
    }
}

.Header-Font {
    font-family: 'Gilroy-SemiBold', sans-serif !important;
}

#MyTask {
    .ant-col-2 {
        text-align: end;
    }
}

#RequestDocsSteps {
    .ant-steps .ant-steps-item-tail::after {
        display: inline-block;
        width: 100%;
        height: 6px !important;
        background: rgba(5, 5, 5, 0.06);
        border-radius: 1px;
        transition: background 0.3s;
        content: "";
    }

    .ant-steps.ant-steps-label-vertical .ant-steps-item-tail {
        margin-inline-start: 60px;
        padding: 3px 12px !important;
    }

    :where(.css-dev-only-do-not-override-1mxasme).ant-steps .ant-steps-item-finish>.ant-steps-item-container>.ant-steps-item-tail::after {
        background: var(--userPrimaryColor) !important;
    }
}

#ppInput {
    .ant-select-single {
        height: 48px;
    }
}

#HeaderSalesProfile {
    background: transparent;
    box-shadow: none;

    .ant-popover .ant-popover-inner {
        padding: 0px !important;
        background: transparent !important;
    }

    .ant-popover .ant-popover-title {
        margin-bottom: 0px !important;
    }
}

#ListingListsImage {
    .ant-image {
        display: flex !important;
        height: 100% !important;
    }
}