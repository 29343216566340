.TitleContainer {
    display: grid;
    grid-template-columns: 10% 90%;
    height: 50px;
    font-size: 20px;
}

.CloseContainer {
    font-weight: bold;
    margin: auto;
    color: white;
}

.CloseButton:hover {
    transform: scale(1.10);
    transition: transform 0.2s;
}

.TitleHeader {
    font-weight: bold;
    margin: auto;
    color: white;
    position: relative;
    left: -5%;
}

.CalcIcon {
    height: 60px;
}