.Outer {
    fill: var(--userPrimaryColor) !important;
}

.Inner {
    fill: transparent !important;
}

.Share:hover .Inner {
    fill: var(--userPrimaryColor) !important;
}