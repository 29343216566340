.contactContainer {
    box-shadow: 0px 1px 10px 0px rgba(68, 126, 183, 0.25);
    border-radius: 5px;
    background: var(--themePrimaryColor) !important;
    padding: 5% 3%;
    /* padding: 2% 0%; */
}

.contactGrid {
    display: grid;
    grid-template-columns: 70% 30%;
    padding: 5% 5% 0%;
}

.contactGrid_Header {
    display: grid;
    grid-template-columns: auto 250px;
}

@media screen and (max-width: 950px) {
    .contactGrid_Header {
        display: grid;
        grid-template-columns: auto;
    }

    .contactGrid_Info {
        display: flex;
        gap: 20px;
    }
}

.contactGridButtons {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.contactButtons {
    min-width: fit-content;
    width: 100px;
    background-color: #FF5555;
    color: white;
    border: 2px solid #FF5555;
}

.contactButtons:hover:not(:disabled) {
    background-color: transparent !important;
    color: #FF5555 !important;
    border: 2px solid #FF5555 !important;
}

.notificationGrid {
    width: 600px;
    display: grid;
    grid-template-columns: 50% 50%;
    grid-gap: 1rem;
    text-align: left;
    margin: 2% 0% 0% 0%;
}

.contactInfo {
    padding-left: 80px;
    font-size: 18px;
    color: var(--themeFontColor) !important;
}

@media screen and (max-width: 992px) {
    .contactInfo {
        padding-left: 60px;
    }

    .contactGridButtons {
        display: flex;
        justify-content: flex-start;
        gap: 20px;
        padding-left: calc(2% + 60px);
        padding-bottom: 2%;
    }

    .notificationGrid {
        width: 100%;
        display: grid;
        grid-template-columns: 90%;
        grid-gap: 1rem;
        text-align: left;
        margin: 5%;
    }
}

.notification {
    /* padding: 2% 0% 0% 4%; */
    padding: 2% 0% 0%;
    font-size: 18px;
    text-align: left;
    font-weight: bold;
    color: var(--themeFontColor) !important;
    font-family: 'Gilroy-SemiBold' !important;
}

.deleteButton {
    background: #FF5555;
    color: white;
    width: 100px;
    min-width: fit-content;
}

.deleteButton:hover {
    background: white !important;
    color: #FF5555 !important;
    border: 1px solid #FF5555 !important;
    width: 100px !important;
    min-width: fit-content !important;
    transition: ease-in-out 0.5s;
}

@media (max-width: 770px) {
    .contactGrid {
        display: grid;
        grid-template-columns: auto;
    }
}

@media (min-width: 992px) and (max-width: 1199px) {
    .contactInfo {
        /* padding-left: calc(3% + 80px); */
        font-size: 18px;
    }
}

.exclaimationCircleFilled {
    color: gold;
    font-size: 22px;
}

.contactGrid_Avatar {
    display: flex;
    /* padding: 5% 5% 1%; */
}

.contactGrid_Info {
    display: flex;
    align-items: center;
    grid-gap: 10%;
    margin: 2%;
}

.contactGrid_InfoName {
    font-size: 22px;
    font-weight: bold;
    min-width: fit-content;
    color: var(--themeFontColor) !important;
    font-family: 'Gilroy-SemiBold' !important;
}

.starFilled {
    color: gold;
    font-size: 22px;
    padding-left: 3%;
}

.contactInfo_Details {
    display: flex;
    grid-gap: 1rem;
    margin: 1% 2%;
}

.contactInfo_Details a {
    color: var(--userPrimaryColor) !important;
}

.contactInfo_Loc {
    display: flex;
    grid-gap: 1rem;
    margin: 1% 2%;
    align-items: flex-start;
    text-align: left;
}

.contactPreferences {
    display: grid;
    grid-template-columns: 50% 50%;
    align-items: center;
}

.contactPreferences_Info {
    display: flex;
    align-items: center;
    grid-gap: 5%;
}

.contactModal {
    display: flex;
    justify-content: space-between;
    margin-bottom: 2%;
    min-height: 80px;
}

.contactModal_Inner {
    display: flex;
    align-items: center;
}

.labelColors {
    color: var(--themeFontColor) !important;
}

.contactGrid_AvatarInitials {
    background-color: var(--userHighlightColor) !important;
    color: var(--themePrimaryColor) !important;
    font-weight: bold;
}