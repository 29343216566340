.Container {
    line-height: 2;
    text-align: left;
}

.Header {
    font-size: 22px;
    font-weight: bold;
    color: black;
}

.SubHeader {
    margin-bottom: 30px;
}

.SubHeader2 {
    margin: 15% 0% 2%;
}

.TimePreferences {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 25px 50px;
}

.IndividualPreference {
    display: grid;
    grid-template-columns: 150px auto;
    gap: 25px;
    min-height: 40px;
}

.IndividualPreference_Label {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    border: 2px solid #CFDCE9;
    color: #447EB7;
    background: white;
    font-weight: bold;
}

.IndividualPreference_Label:hover {
    cursor: pointer;
    border: 2px solid #447EB7 !important;
}

.Selected_IndividualPreference_Label {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    color: white;
    border: 2px solid #447EB7;
    background: #447EB7;
    font-weight: bold;
    cursor: pointer;
}

.IndividualTime {
    display: grid;
    grid-template-columns: 80px auto;
}

.TimeDistribution {
    display: flex;
    justify-content: space-between;
    gap: 5%;
}

@media screen and (max-width: 1420px) and (min-width: 1200px) {
    .IndividualTime {
        display: grid;
        grid-template-columns: repeat(1, 1fr);
    }

    .TimePreferences {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 20% 5%;
    }
}

@media screen and (max-width: 1200px) {
    .TimePreferences {
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        gap: 10% 5%;
    }

    .IndividualPreference {
        display: grid;
        grid-template-columns: 200px auto;
    }

    .SubHeader2 {
        margin: 40% 0% 2%;
    }
}

@media screen and (max-width: 610px) {
    .IndividualPreference {
        display: grid;
        grid-template-columns: 100px auto;
        gap: 25px;
        min-height: 40px;
    }

    .IndividualTime {
        display: grid;
        grid-template-columns: repeat(1, 1fr);
    }

    .TimeDistribution {
        display: flex;
        gap: 3%;
    }

    .SubHeader2 {
        margin: 60% 0% 5%;
    }
}

@media screen and (min-width: 1320px) {
    .SubHeader2 {
        margin: 5% 0% 2%;
    }
}

@media screen and (max-width: 1420px) and (min-width: 1320px) {
    .SubHeader2 {
        margin: 12% 0% 2%;
    }
}

.ContactPreference {
    width: 320px;
    display: flex;
    justify-content: space-between;
}

.IndividualContactPreference {
    width: 120px;
    height: 40px;
    display: grid;
    grid-template-columns: 40px 80px;
    text-align: center;
    align-items: center;
    border-radius: 10px;
    border: 2px solid #CFDCE9;
    background: white;
    color: #447EB7;
    font-weight: bold;
}

.IndividualContactPreference:hover {
    cursor: pointer;
    border: 2px solid #447EB7;
}

.Selected_IndividualContactPreference {
    width: 120px;
    height: 40px;
    display: grid;
    grid-template-columns: 40px 80px;
    text-align: center;
    align-items: center;
    border-radius: 10px;
    background: #447EB7;
    color: white;
    border: 2px solid #447EB7;
    font-weight: bold;
}

.ContactPreference_LabelText {
    text-align: left;
}

.ContactPreference_Select {
    height: 40px;
    text-align: left;
}