.backgroundFill {
    fill: transparent !important;
    /* fill: var(--userHighlightColor) !important; */
}

.GridIcon:hover .backgroundFill {
    fill: var(--userHighlightColor) !important;
}

.backgroundFill_selected {
    fill: var(--userHighlightColor) !important;
}

.selectedBorder {
    stroke: var(--userPrimaryColor) !important;
}

.unselectedBorder {
    stroke: transparent !important;
}

.GridIcon:hover .unselectedBorder {
    stroke: var(--userHighlightColor) !important;
}

.selectedBlocks {
    stroke: var(--userPrimaryColor) !important;
    fill: var(--userPrimaryColor) !important;
}

.unselectedBlocks {
    stroke: var(--userPrimaryColor) !important;
    fill: transparent !important;
}

.GridIcon:hover .unselectedBlocks {
    stroke: var(--userPrimaryColor) !important;
    fill: var(--userPrimaryColor) !important;
}