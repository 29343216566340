.EmptyItem {
    margin: 10px 0px;
    border-radius: 10px;
    box-shadow: 0px 1px 10px 0px rgba(68, 126, 183, 0.25);
    background-color: var(--themePrimaryColor) !important;
    height: 80px;
}

.EmptyItem_Content {
    height: inherit;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    color: var(--userPrimaryColor) !important;
}

.UnreadItem {
    display: grid;
    position: relative;
    left: -2%;
}

.ReadItem {
    display: grid;
}

.UnreadItemGrid {
    display: grid;
    grid-template-columns: 2% 100%;
}

.MarginCenter {
    margin: auto;
}

.IndividualItem {
    display: grid;
    grid-template-columns: 10% 68% 10% 6% 6%;
    background-color: var(--themePrimaryColor) !important;
    margin: 10px 0%;
    border-radius: 10px;
    box-shadow: 0px 1px 10px 0px rgba(68, 126, 183, 0.25);
    cursor: pointer;
}

.IndividualItem:hover {
    background-color: #EEEEEE;
}

.IndividualItem_Icon {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 15% 10%;
}

.IndividualItem_Content {
    margin: auto 0%;
    text-align: start;
}

.IndividualItem_Header {
    font-weight: bolder;
    font-size: 16px;
    color: var(--userPrimaryColor) !important;
}

.IndividualItem_Subtext {
    color: var(--themeFontColor) !important;
}

.Flex {
    display: flex;
}

.Timeline {
    margin: auto 0%;
    color: darkgray;
    text-align: end;
}

.IndividualItem_Divider {
    height: 70%;
    margin: auto;
    border-inline-start: 2px solid lightgray;
}