.Container {
    color: var(--themeFontColor) !important;
    height: 90%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.Header {
    font-size: x-large;
    font-weight: bold;
    text-align: left;
}

.IndividualRequirement {
    display: grid;
    grid-template-columns: 26% 35px auto;
    align-items: center;
    gap: 2%;
    padding: 10px 0px;
    height: 110px;
    border-bottom: 2px solid var(--themeSecondaryColor) !important;
}


.IndividualRequirement:last-child {
    padding-bottom: 2%;
}

.IndividualRequirement:hover {
    background-color: var(--userHighlightColor) !important;
    cursor: pointer;
}

.Dates {
    text-align: left;
    color: darkgray;
    font-size: small;
    margin: auto 0%;
}

.Individual {
    display: flex;
    align-items: center;
}

.IndividualHeader {
    color: var(--userPrimaryColor) !important;
    font-weight: bold;
    text-align: left;
}

.IndividualDescription {
    text-align: left;
}

.ReqList {
    max-height: 410px !important;
    overflow-y: scroll;
}

.ReqList::-webkit-scrollbar {
    width: 6px;
}

.ReqList::-webkit-scrollbar-track {
    background: transparent;
}

.ReqList::-webkit-scrollbar-thumb {
    background-color: var(--userPrimaryColor) !important;
    border-radius: 20px;
}

.Empty {
    display: flex;
    justify-content: center;
    /* Center horizontally*/
    align-items: center;
    /* Center vertically */
    margin: auto;
}

.Hide {
    display: none;
}

@media screen and (max-width: 800px) {
    .Empty {
        display: grid;
        margin: auto;
        margin-top: 15%;
    }
}