.Header {
    font-weight: bold;
}

.HeaderDetails {
    color: #6c757d;
    font-size: 21px;
}

.ContentHeader {
    display: flex;
}

.Content {
    max-height: 73vh;
    overflow-y: hidden;
}