.PopoverContainer {
    width: 100px;
    padding: 5px;
    margin-top: 10px;
}

.Kebab_Button {
    border: none;
}

.Kebab_Button:disabled {
    background: #EEEEEE !important;  
}