@import url("../../assets/styles/fonts.css");

.Container {
    background: #F4F4F4;
    height: 100vh;
    font-family: 'Gilroy-Regular' !important;
}

.SentSuccess {
    color: black;
    font-weight: bold;
    font-size: x-large;
}

.SentSuccess_Sub {
    color: black;
    text-align: center;
    font-size: large;
}

.SentSuccess_Button {
    color: white;
    background: #447EB7 !important;
    border-radius: 5px;
    width: 200px;
    font-weight: bold;
    font-size: 18px;
    height: 40px;
    margin-top: 5%;
}

.SentSuccess_Button:hover {
    background: #CFDCE9 !important;
    color: #447EB7 !important;
}

@media screen and (max-width: 768px) {
    .Container {
        background: url("https://lanternpublicstaticfiles.blob.core.windows.net/main/Beacon_JoinUs.svg");
        background-size: cover;
        height: 100vh;
    }

    .SentSuccess {
        color: white;
        font-weight: bold;
        font-size: x-large;
    }

    .SentSuccess_Sub {
        color: white;
        text-align: center;
        font-size: large;
    }
}