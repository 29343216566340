.errorBody {
    background: var(--themePrimaryColor) !important;
    width: 100vw;
    height: 100vh;
    color: var(--themeFontColor) !important
}

.center {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 10px;
    text-align: center;
}

.textStyle {
    font-family: 'Gilroy-Regular';
    font-weight: normal;
}

.textFont {
    font-family: 'Gilroy-Regular';
    font-size: 1.5rem;
}

.button {
    background-color: var(--userPrimaryColor, #447EB7);
    color: white;
}

.button:hover{
    background-color: var(--userHighlightColor, #447EB7) !important;
    color: var(--userPrimaryColor) !important;
}

@media only screen and (max-width: 568px) {
    .textFont {
        font-family: 'Gilroy-Regular';
        font-size: 1rem !important;
    }

    .textStyle {
        font-family: 'Gilroy-Regular';
        font-weight: normal;
        font-size: 1rem !important;
    }

}