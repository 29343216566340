.inner-content {
    display: grid;
    grid-template-columns: 25% 45% !important;
    min-width: fit-content;
    width: 100%;
}

@media (max-width: 1030px) {
    .inner-content {
        grid-template-columns: 35% 60% !important;
    }
}

@media (max-width: 800px) {
    .inner-content {
        grid-template-columns: repeat(1, 1fr) !important;
    }
}

.vertical-content {
    width: 100%;
}

.input {
    width: 100%;
    min-width: fit-content;
    height: 35px;
    min-height: fit-content;
    border-bottom: 2px solid var(--userHighlightColor) !important;
    display: flex;
    justify-content: space-between;
    padding: 0% 2%;
    align-items: center;
    cursor: not-allowed;
}

.hide {
    visibility: hidden;
}

.visible {
    visibility: visible;
}

.VerticalWarning {
    color: var(--themeDangerColor);
    font-size: smaller;
}

.HorizontalWarning {
    color: var(--themeDangerColor);
    font-size: smaller;
    margin-top: -10%;
    white-space: nowrap;
    padding-left: 100%;
}