.ApplicationTypeModal_Header>div:first-child {
    font-family: 'Gilroy-SemiBold';
    font-size: 22px;
    display: flex;
    justify-content: space-between;
}

.ApplicationTypeModal_Header>div:first-child>div:last-child {
    cursor: pointer;
    padding: 0px 4px;
    border-radius: 5px;
}

.ApplicationTypeModal_Header>div:first-child>div:last-child:hover {
    background-color: var(--themeSecondaryColor) !important;
}

.ApplicationTypeModal_Header>div:last-child {
    font-family: 'Gilroy-Regular';
    font-size: 16px;
    color: darkgray;
}

.ApplicationHeader_Button {
    display: flex;
    background: var(--userPrimaryColor) !important;
    min-width: fit-content;
}

.ApplicationHeader_Button:hover {
    background: var(--userHighlightColor) !important;
    color: var(--userPrimaryColor) !important;
}

.ApplicationTypeModal_Content {
    --n: 3;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(max(180px, 80%/var(--n)), 1fr));
    gap: 15px;
    text-align: center;
    margin: 2% 0%;
}

.ApplicationTypeModal_Content>div {
    padding: 5% 0%;
    border-radius: 5px;
    font-family: 'Gilroy-SemiBold' !important;
}

.ApplicationTypeModal_Content>div:hover {
    background-color: var(--themeSecondaryColor) !important;
    cursor: pointer;
}