.inner-content{
    display: grid;
    grid-template-columns: 25% 45% !important;
    min-width: fit-content;
    margin-bottom: 5%;
}

@media (max-width: 1030px) {
    .inner-content {
        grid-template-columns: 35% 60% !important;
    }
}

@media (max-width: 800px) {
    .inner-content {
        grid-template-columns: repeat(1, 1fr) !important;
    }
}

.VerticalWarning {
    color: var(--themeDangerColor);
    font-size: smaller;
}

.HorizontalWarning {
    color: var(--themeDangerColor);
    font-size: smaller;
    margin-top: -25px;
    white-space: nowrap;
    padding-left: 100%;
}

@media screen and (min-width: 575px) and (max-width: 800px) {
    .HorizontalWarning {
        margin-top: 0 !important;
        padding-left: 0 !important;
    }
}