@import url("../../assets/styles/fonts.css");

.Drawer {
    width: 435px !important;
    position: fixed;
    top: 0;
}

.Client_Button {
    background: var(--userPrimaryColor) !important;
    color: white !important;
    min-width: fit-content;
}

.Client_Button:hover {
    background: var(--userHighlightColor) !important;
    color: var(--userPrimaryColor) !important;
}

.Client_Button_Save {
    display: flex;
    justify-content: center;
}

.Client_Button_AutoFill {
    border: 1px solid var(--userHighlightColor) !important;
    border-radius: 5px !important;
    color: var(--userPrimaryColor) !important;
    background-color: white !important;
}

.Client_Button_AutoFill:hover {
    border: 1px solid var(--userPrimaryColor) !important;
}

.AutoFill_AlertContainer {
    display: grid;
    grid-template-columns: 20px auto;
    align-items: start;
    gap: 10px;
    font-family: 'Gilroy-UltraLight' !important;
}

.AutoFillCard {
    background: var(--autoFillDisabled);
    padding: 5px;
    border-radius: 8px;
}

.ExclamationCircle {
    color: #EDCB00;
    font-size: 20px;
}

.AutoFill_AlertText {
    font-size: 15;
    font-weight: 100;
    color: #3F3D33;
}

.AutoFill_Button {
    width: 100%;
    display: flex;
    justify-content: center;
    padding: 15px 0px 0px;
}