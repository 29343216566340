.PaymentSchedule_Loader {
    display: flex;
    justify-content: center;
    align-items: center;
}

.PaymentSchedule_Buttons {
    display: flex;
    justify-content: space-between;
    margin: 3% 1% 0%;
}

.PaymentSchedule_InitiateFunding {
    min-width: fit-content;
    width: 20%;
    margin-left: auto;
    float: right;
    height: 40px;
}

.PaymentSchedule_FundingInitiated {
    display: flex;
    justify-content: flex-end;
}

.PaymentSchedule_FundingInitiatedButton {
    background-color: green;
}

.PaymentSchedule_FundingInitiatedButton:hover {
    background-color: rgb(0, 128, 0, 0.8) !important;
}

.HeaderName {
    font-weight: bold;
    font-size: 22px;
    display: flex;
}

.HeaderName_Custom {
    color: gray;
    padding-left: 5px;
}

.ClientBrokerSchedule_Align {
    text-align: left;
}

.ClientBrokerSchedule_Alert {
    background-color: lightgray;
    border-radius: 8px;
    font-weight: bold;
}

.ClientBrokerSchedule_Alert_Message {
    display: flex;
    justify-content: space-between;
    padding: 0% 2%;
    align-items: center;
}

.ClientBrokerSchedule_Alert_Button {
    background: white;
    border: 1px solid darkgray;
    font-weight: bold;
}

.ClientBrokerSchedule_View_PPS_Button {
    background: white;
    border: 1px solid darkgray;
    font-weight: bold;
}

.ClientBrokerSchedule_Print {
    text-align: right;
    margin: 2% 2% 0% 0%;
}

.ClientBrokerSchedule_HTML {
    overflow-y: scroll;
    margin: 2%;
    max-height: 44vh;
}

.Banner_Buttons {
    display: flex;
    grid-gap: 5%;
}

.WarningAlert{
    display: block;
}

.Hide{
    display: none;
}