.deleteButton {
    background: #FF5555;
    color: white;
    width: 100px;
    min-width: fit-content;
}

.deleteButton:hover {
    background: white !important;
    color: #FF5555 !important;
    border: 1px solid #FF5555 !important;
    width: 100px !important;
    min-width: fit-content !important;
    transition: ease-in-out 0.5s;
}