.Documents_Container {
    width: 100%;
    display: grid;
    grid-template-columns: 35% auto;
    gap: 25px;
}

@media screen and (max-width: 1200px) {
    .Documents_Container {
        grid-template-columns: 100%;
        margin-bottom: 40px;
    }
}

.Container {
    width: 100%;
    height: max-content;
    height: 100%;
    min-height: 100%;
    background: var(--themePrimaryColor) !important;
    box-shadow: 0px 1px 10px 0px rgba(68, 126, 183, 0.25);
    border-radius: 10px;
    padding: 20px;
    margin: 25px 0px;
}

.ContainerDocumentRequest {
    width: 100%;
    height: max-content;
    height: 100%;
    min-Height: 100%;
    background: var(--themePrimaryColor) !important;
    border-radius: 10px;
    padding: 20px;
    margin: 25px 0px;
    box-shadow: none;
}

.Docs_Header {
    font-family: 'Gilroy-SemiBold';
    font-size: large;
    text-align: left;
    margin: 5px 0px 0px;
    color: var(--themeFontColor) !important;
}

.Docs_SubHeader {
    font-family: 'Gilroy-UltraLight';
    font-size: small;
    text-align: left;
    color: var(--themeFontColor) !important;
}

.Docs_List {
    max-height: 630px;
    overflow-y: auto;
    scroll-behavior: smooth;
}

.Docs_List::-webkit-scrollbar {
    width: 8px;
}

.Docs_List::-webkit-scrollbar-track {
    background: var(--themeSecondaryColor) !important;
}

.Docs_List::-webkit-scrollbar-thumb {
    background: var(--userPrimaryColor) !important;
    border-radius: 10px;
    border: 1px solid #F4F4F4;
}

.Header {
    font-family: 'Gilroy-SemiBold';
    font-size: large;
    text-align: left;
    margin: 5px 0px 25px;
    color: var(--themeFontColor) !important;
}

.InnerContainer {
    display: grid;
    grid-template-rows: auto 168px;
    height: 85%;
}

.FullInnerContainer {
    display: grid;
    grid-template-rows: 212px 200px 168px;
    gap: 20px;
}

.RequestDocs_FullInnerContainer {
    display: grid;
    grid-template-rows: 212px 100px 168px;
    gap: 20px;
}

.HideContainer {
    display: none;
}

.DocsContainer {
    overflow-y: auto;
    scroll-behavior: smooth;
}

.DocsContainer::-webkit-scrollbar {
    width: 8px;
}

.DocsContainer::-webkit-scrollbar-track {
    background: var(--themeSecondaryColor) !important;
}

.DocsContainer::-webkit-scrollbar-thumb {
    background: var(--userPrimaryColor) !important;
    border-radius: 10px;
    border: 1px solid #F4F4F4;
}