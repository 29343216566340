.Layout {
    text-align: left;
    font-size: large;
    color: var(--themeFontColor) !important;
}

.AgentLogo {
    width: 50px !important;
}

.Container {
    margin: 20px 0px;
    background: var(--themePrimaryColor) !important;
    padding: 20px;
    box-shadow: 0px 1px 10px 0px rgba(68, 126, 183, 0.25);
    border-radius: 10px;
    border-left: 15px solid var(--userPrimaryColor) !important;
}

.Subject {
    font-size: x-large;
    font-weight: bold;
    padding-top: 1%;
}

.CopyButton {
    background: var(--userSecondaryColor) !important;
    color: #FFFFFF !important;
    width: 150px;
    height: 40px;
}

.CopyButton:hover {
    background: var(--userSecondaryHoverColor) !important;
    color: #FFFFFF !important;
}

.SendNow {
    height: 40px;
    width: 150px;
}

.FlexSubject {
    display: flex;
    justify-content: space-between;
}

.ActionButtons {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
}

@media screen and (max-width: 768px) {
    .FlexSubject {
        display: grid;
        gap: 20%;
        padding: 10% 0%;
    }

    .ActionButtons {
        display: flex;
        justify-content: space-between;
        margin-bottom: 40px;
    }
}

.EmailBody {
    line-height: 1;
}

.CopyableEmailBody {
    position: absolute;
    top: -9999px;
}