.inner-content{
    display: grid;
    grid-template-columns: 25% 45% !important;
    min-width: fit-content;
}

@media (max-width: 1030px) {
    .inner-content {
        grid-template-columns: 35% 60% !important;
    }
}

@media (max-width: 800px) {
    .inner-content {
        grid-template-columns: repeat(1, 1fr) !important;
    }
}

.uploadButtons_vertical {
    color: var(--themeFontColor) !important;
    background: var(--themePrimaryColor) !important;
    height: fit-content !important;
    background: var(--themePrimaryColor) !important;
    width: 250px !important;
}

.uploadButtons_horizontal_error {
    color: var(--themeFontColor) !important;
    background: var(--themePrimaryColor) !important;
    height: fit-content !important;
    background: var(--themePrimaryColor) !important;
    width: 400px !important;
    border-color: red;
}

.uploadButtons_horizontal {
    color: var(--themeFontColor) !important;
    background: var(--themePrimaryColor) !important;
    height: fit-content !important;
    background: var(--themePrimaryColor) !important;
    width: 400px !important;
}

.uploadButtons_vertical:hover,
.uploadButtons_horizontal:hover {
    border: 1px solid var(--userHighlightColor) !important;
    color: var(--userHighlightColor) !important;
}

.uploadButtons_horizontal_error:hover {
    border: 1px solid red !important;
    color: red !important;
}