@import url("../../assets/styles/fonts.css");

.Label .ReqLabel {
    display: inline-block;
    flex-grow: 0;
    overflow: hidden;
    white-space: nowrap;
    text-align: start !important;
    vertical-align: middle;
    font-family: 'Gilroy-Medium' !important;
}

.ReqLabel::after {
    display: inline-block;
    margin-inline-start: 4px;
    color: #ff4d4f;
    font-size: 16px;
    font-family: SimSun, sans-serif;
    line-height: 1;
    content: "*";
}

.PopOverParsedContent {
    display: grid;
    grid-template-columns: auto auto;
    gap: 10px;
    padding: 20px;
    color: black;
}

.PopOver {
    display: flex;
    gap: 8px;
}

.TotalsBorder {
    border-top: 2px solid black;
}