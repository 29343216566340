.assetButton {
    background: var(--userPrimaryColor) !important;
    min-width: fit-content;
    width: 100%;
    display: block;
    height: 40px;
}

.assetButton:hover {
    background: var(--userHighlightColor) !important;
    color: var(--userPrimaryColor) !important;
}