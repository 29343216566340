.CancelButton {
    background-color: #FF5555;
    margin-top: 2%;
    height: 40px;
    box-shadow: none !important;
}

.CancelButton:hover {
    background-color: white !important;
    color: #FF5555 !important;
    border: 2px solid #FF5555 !important;
    /* box-shadow: none !important; */
}

.TitleFlex {
    display: flex;
}

.TitleHeader {
    padding-left: 5px;
}

.SubHeader {
    font-size: 14px;
}

.ModalActions {
    display: flex;
    justify-content: space-between;
}

.Error {
    color: red;
}

.ActionButton {
    height: 40px;
    margin-right: 10px;
}

.Close {
    color: grey;
}

.Close:hover {
    color: black;
    transition: all 0.3s;
}