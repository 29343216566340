@import url("../../assets/styles/fonts.css");

.Label {
    font-size: 1.5rem;
    font-weight: bold;
    font-family: 'Gilroy-Regular';
    color: var(--themeFontColor) !important;
}

.Label-header {
    text-align: left;
}

.tagName {
    width: 60%;
    min-width: 5vw;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.redTag {
    width: 60%;
    min-width: fit-content;
    white-space: nowrap;
    color: #FF5555;
    background-color: #FF555520;
    text-align: center;
    border: none;
    padding: 3px 10px;
    border-radius: 5px;
}

.greenTag {
    width: 60%;
    min-width: fit-content;
    white-space: nowrap;
    color: var(--userSecondaryColor);
    background-color: var(--userSecondaryColor)40;
    text-align: center;
    border: none;
    padding: 3px 10px;
    border-radius: 5px;
}

.center {
    position: absolute;
    top: 50%;
    transform: translate(70%, -50%);
    padding: 10px;
}

.nodataTitle {
    font-size: 1rem;
    font-weight: bold;
    font-family: 'Gilroy-Regular';
}



/* 
.center{
    position: relative;
    background: gray;
    height: 100%;
    width: 100%;
}


.child {
    background-color: orange;
    position: absolute;
    top: 100%;
    left: 50%;
    transform: translate(-50%,-50%);
  } */

.GreenTags {
    color: var(--userSecondaryColor) !important;
    background: #8ECEAE40 !important;
}

.RedTags {
    color: #FF5555 !important;
    background: #FF555540 !important;
}

.Mobile_Content {
    margin: 10px 0px;
    font-family: 'Gilroy-Regular' !important;
    width: 100%;
    max-height: 350px;
    overflow: hidden auto;
    padding-right: 10px;
    scroll-behavior: smooth;
}

.Mobile_Content::-webkit-scrollbar {
    width: 5px;
}

.Mobile_Content::-webkit-scrollbar-track {
    background: transparent;
}

.Mobile_Content::-webkit-scrollbar-thumb {
    background: darkgray !important;
    border-radius: 10px;
    border: 0px solid #F4F4F4;
}

.Mobile_Content>div {
    display: grid;
    gap: 5px;
    padding: 10px 0px;
    text-align: left;
}

.Mobile_Content>div:hover {
    background-color: var(--userHighlightColor) !important;
    cursor: pointer;
}

.Mobile_Content>div:not(:last-child) {
    border-bottom: 1px solid #CCCCCC;
}

.Mobile_Content>div>div {
    display: grid;
    grid-template-columns: 33% auto 33%;
    gap: 5px
}

.Mobile_Content>div>div:is(:last-child) {
    align-items: center;
}

.Mobile_Content>div>div:is(:first-child)>div:first-child {
    font-family: 'Gilroy-SemiBold';
}

.Mobile_Content>div>div:is(:first-child)>div:last-child {
    text-align: right;
}

.Mobile_Content_Asset {
    color: var(--userPrimaryColor) !important;
}