/* SVG Header Profile Icon CSS */

.Profile:hover .Profile_BG,
.Profile_BG_Selected {
  fill: var(--userHighlightColor) !important;
}

.Profile_Fill {
  fill: var(--userPrimaryColor) !important;
}

.Profile_Stroke {
  stroke: var(--userPrimaryColor) !important;
}