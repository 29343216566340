@font-face {
    font-family: 'Gilroy-Medium';
    src: url("../../../public/fonts/Gilroy-Medium.otf") format("opentype");
}

@font-face {
    font-family: 'Gilroy-Regular';
    src: url("../../../public/fonts/Gilroy-Regular.otf") format("opentype");
}

@font-face {
    font-family: 'Gilroy-SemiBold';
    src: url("../../../public/fonts/Gilroy-SemiBold.otf") format("opentype");
}

@font-face {
    font-family: 'Gilroy-UltraLight';
    src: url("../../../public/fonts/Gilroy-UltraLight.otf") format("opentype");
}

.Font_SemiBold {
    font-family: 'Gilroy-SemiBold', sans-serif !important;
}

.Font_Regular {
    font-family: 'Gilroy-SemiBold', sans-serif !important;
}

.Font_Medium {
    font-family: 'Gilroy-Medium', sans-serif !important;
}

.Font_UltraLight {
    font-family: 'Gilroy-UltraLight', sans-serif !important;
}