@import url("../../assets/styles//fonts.css");

.DocumentContainer {
    display: grid;
    grid-template-columns: 25px auto 60px;
    gap: 10px;
    margin: 10px 0px;
    padding: 10px 0px;
    border-bottom: 2px solid #CCCCCC !important;
    align-items: center;
    text-align: left;
}

.RequiredIcon {
    color: #FF5555 !important;
    font-size: larger !important;
}

.OptionalIcon {
    color: var(--userPrimaryColor) !important;
    font-size: larger !important;
}

.Header {
    font-family: 'Gilroy-Regular' !important;
    font-weight: bold;
    color: var(--themeFontColor) !important;
}

.SubHeader {
    font-family: 'Gilroy-UltraLight' !important;
    color: var(--themeFontColor) !important;
}

.ActionIcons {
    display: flex;
    gap: 10px;
}

.HideContainer {
    display: none;
}

.CenterIcon {
    display: flex;
    justify-content: center;
    align-items: center;
}