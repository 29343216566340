@import url("../../assets/styles/fonts.css");

.Container {
    background: var(--themePrimaryColor) !important;
    box-shadow: 0px 1px 10px 0px rgba(68, 126, 183, 0.25);
    border-radius: 10px;
    padding: 10% 20px;
    line-height: 1.5;
    /* font-family: 'Gilroy-Semibold' !important; */
    font-weight: bold;
    font-size: large;
}

.Container>:last-child {
    margin: 20px 0px 0px;
    height: 40px;
}