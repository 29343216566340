/* SVG Header Notification Icon CSS */
.Notification:hover .Notification_BG,
.Notification_BG_Selected {
  fill: var(--userHighlightColor) !important;
}

.Notification_Selected {
  fill: var(--userPrimaryColor) !important;
}

.Notification_Un_Selected {
  fill: transparent !important;
}