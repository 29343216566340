@import url("../../assets/styles/fonts.css");

.RequestAsset_Title {
    display: flex;
    gap: 20px;
    font-size: 22px;
    font-family: 'Gilroy-SemiBold' !important;
}

.RequestAsset_Title>:not(div) {
    cursor: pointer;
}

.RequestAsset_Title>:not(div):hover {
    transform: scale(1.2);
}

.RequestAsset_Title>div {
    color: var(--userPrimaryColor) !important;
}

.RequestAsset_Header {
    font-size: 13px;
    margin: 10px 0px;
}

.RequestAsset_Label {
    display: flex;
    font-weight: bold;
    font-family: 'Gilroy-SemiBold' !important;
    line-height: 1;
}

.RequestAsset_Label::after {
    content: "*";
    font-family: 'Gilroy-UltraLight' !important;
    font-weight: lighter;
    padding-left: 2px;
    color: var(--themeDangerColor) !important;
    font-size: 32px;
}

.RequestAsset_Container {
    display: grid;
    grid-template-columns: 50% 50%;
    gap: 10px;
}

.RequestAccess_Input_Error {
    color: var(--themeDangerColor) !important;
    font-size: 13px;
}

.RequestAccess_Input {
    border: 1px solid var(--userHighlightColor) !important;
    border-radius: 7px;
    padding: 5px 10px;
}

.RequestAccess_Input-Error {
    border: 1px solid var(--themeDangerColor) !important;
    border-radius: 7px;
    padding: 5px 10px;
}

.RequestAccess_Input:focus-within {
    border: 1px solid var(--userPrimaryColor) !important;
}

.RequestAccess_Input>span:first-child,
.RequestAccess_Input-Error>span:first-child {
    font-size: 13px;
    color: gray;
}

.RequestAsset_Action {
    display: flex;
    justify-content: center;
}

.Hide {
    visibility: hidden;
}