@import url("../../../assets/styles/fonts.css");

.NotificationsBody {
    color: var(--themeFontColor) !important;
}

.NotificationTitle {
    font-weight: bolder;
    font-size: 20px;
    padding: 2% 0%;
    font-family: 'Gilroy-SemiBold' !important;
    color: var(--userPrimaryColor) !important;
    text-transform: uppercase;
}

/* .NotificationDetailsBlock {
    box-shadow: 0px 1px 10px 0px rgba(68, 126, 183, 0.25);
    border-radius: 5px;
    background: var(--themePrimaryColor) !important;
    padding: 3%;
    text-align: left;
} */

.NotificationTitleInfo {
    font-weight: bold;
    text-transform: uppercase;
}

.ClientInfo_Container {
    padding: 2% 0%;
    font-weight: bold;
    font-size: 14px;
}

.ClientInfo_Container:first-child {
    text-transform: uppercase;
}

.NotificationGridBlock {
    display: grid;
    grid-gap: 1rem;
    width: 80%;
    margin: auto;
}

.NotificationDetailsGrid {
    display: grid;
    grid-template-columns: repeat(2, 1fr) !important;
    grid-gap: 1rem;
    min-width: fit-content;
}

.InvoicingInstruction {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 1rem;
    margin: auto;
    min-width: fit-content;
    width: 40%;
}

@media (max-width: 1100px) {
    .NotificationGridBlock {
        width: 90%;
    }

    .InvoicingInstruction {
        width: 60%;
    }
}

@media (max-width: 770px) {
    .NotificationDetailsGrid {
        grid-template-columns: repeat(1, 1fr) !important;
    }

    .NotificationGridBlock {
        width: 100%;
    }

    .InvoicingInstruction {
        grid-template-columns: repeat(1, 1fr);
        width: 100%;
    }
}

.InstructionsTitle {
    text-transform: uppercase;
    font-weight: bold;
    padding: 2% 0% 1%;
    font-size: large;
    font-family: 'Gilroy-SemiBold' !important;
    color: var(--userPrimaryColor) !important;
}

.Instructions_SubTitle {
    width: 70%;
    margin: 0% auto;
    font-size: medium;
}

.Instructions_SubTitle span {
    font-family: 'Gilroy-SemiBold' !important;
    text-transform: uppercase;
}

.ActionIcons {
    display: flex;
    gap: 10px;
}

.CenterIcon {
    display: flex;
    justify-content: center;
    align-items: center;
}

.NotificationDetailsBlock {
    box-shadow: 0px 1px 10px 0px rgba(68, 126, 183, 0.25);
    border-radius: 5px;
    background: var(--themePrimaryColor) !important;
    padding: 1%;
    text-align: left;
}

.NotificationDetailsTitle {
    text-transform: uppercase;
    font-weight: bolder;
    padding: 2% 0%;
    font-family: "Gilroy-SemiBold" !important;
    color: var(--userPrimaryColor) !important;
}