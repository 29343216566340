.subHeader {
    color: var(--themeFontColor) !important;
    padding: 0% 0% 5% 0%;
}

.ModifyButton {
    background: #FFD700;
    color: black;
    min-width: fit-content;
    width: 20%;
    height: 40px;
}