@import url("../../assets/styles/fonts.css");

.Container {
    width: 100%;
    height: max-content;
    height: 100%;
    min-height: 100%;
    background: var(--themePrimaryColor) !important;
    box-shadow: 0px 1px 10px 0px rgba(68, 126, 183, 0.25);
    border-radius: 10px;
    padding: 20px;
    margin: 25px 0px;
}

.HeaderContent {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
}

.Header {
    font-family: 'Gilroy-SemiBold';
    font-size: large;
    text-align: left;
    margin: 5px 0px 25px;
    color: var(--themeFontColor) !important;
}

.HeaderButtons {
    display: flex;
    gap: 20px;
}

@media screen and (min-width: 621px) and (max-width: 1020px) {
    .HeaderContent {
        display: block;
    }

    .HeaderButtons {
        display: flex;
        justify-content: space-between;
    }
}

.pgContainer {
    margin-bottom: 20px;
    padding-bottom: 20px;
    border-bottom: 2px solid var(--themeSecondaryColor);
}

.pg-OtherFields {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(19rem, 1fr));
    gap: 20px;
}

.pg-radioFields {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(15rem, 1fr));
    gap: 20px;
}

@media screen and (max-width: 620px) {
    .HeaderContent {
        display: block;
    }

    .HeaderButtons {
        display: grid;
        text-align: left;
    }
}

.DeletePG {
    display: flex;
    justify-content: flex-end;
    width: 100%;
}

.Hide {
    display: none;
}

.IndividualCoApp {
    display: grid;
    grid-template-columns: auto 30px;
    gap: 20px;
}

.ActionButton {
    height: 40px;
}

.ActionButton:disabled {
    background-color: var(--themeSecondaryColor) !important;
    color: darkgray !important;
}

.ActionButton_CoApp {
    height: 40px;
    background: var(--userSecondaryColor) !important;
    color: white !important;
}

.ActionButton_CoApp:hover {
    background: var(--userSecondaryHoverColor) !important;
    color: white !important;
}

.ActionButton_CoApp:disabled {
    background-color: var(--themeSecondaryColor) !important;
    color: darkgray !important;
}

.SIN_container {
    position: relative;
}

.SIN_edit {
    position: absolute;
    top: 42%;
    right: 2%;
}