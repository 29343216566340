.Requirements_Table {
    background: var(--themePrimaryColor) !important;
    border-radius: 5px;
    box-shadow: 0px 1px 10px 0px rgba(68, 126, 183, 0.25);
    padding: 2%;
}

.Requirements_Table_Button {
    box-shadow: none !important;
    border-radius: 10px !important;
}

.Requirements_Table_Button:hover {
    border: 1px solid var(--userPrimaryColor) !important;
}