@import url("../../../assets/styles/fonts.css");

.NotificationsBody {
    color: var(--themeFontColor) !important;
}

.NotificationTitle {
    font-weight: bolder;
    font-size: 20px;
    padding: 2% 0%;
    color: var(--userPrimaryColor) !important;
    text-transform: uppercase;
    font-family: 'Gilroy-SemiBold' !important;
}

.NotificationTitleInfo {
    font-weight: bold;
    text-transform: uppercase;
}

.NotificationGridBlock {
    display: grid;
    grid-gap: 1rem;
    width: 80%;
    margin: auto;
}

.NotificationDetailsGrid {
    display: grid;
    grid-template-columns: repeat(2, 1fr) !important;
    grid-gap: 1rem;
    min-width: fit-content;
}

@media (max-width: 1100px) {
    .NotificationGridBlock {
        width: 90%;
    }

    .InvoicingInstruction {
        width: 60%;
    }
}

@media (max-width: 770px) {
    .NotificationDetailsGrid {
        grid-template-columns: repeat(1, 1fr) !important;
    }

    .NotificationGridBlock {
        width: 100%;
    }

    .InvoicingInstruction {
        grid-template-columns: repeat(1, 1fr);
        width: 100%;
    }
}

.InstructionsTitle {
    text-transform: uppercase;
    font-weight: bold;
    padding: 2% 0% 1%;
    font-size: large;
    color: var(--userPrimaryColor) !important;
    font-family: 'Gilroy-SemiBold' !important;
}