.center{
    display: flex;
    justify-content: center; /* Center horizontally*/
    align-items: center; /* Center vertically */
    margin: auto;
}

.centerTransform{
    position: absolute;
    top: 50%;
    transform: translate(30%, -50%);
    padding: 10px;
}

.fitContent {
    display: "flex";
    width: "fit-content" ;
}


/* @media (min-device-width : 768px)  {
    .fitContent {
        display: "";
        width: "fit-content" ;
    }
} */