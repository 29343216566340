@import url("../../assets/styles/fonts.css");

.Container {
    display: flex;
    padding: 0 5%;
    background: var(--themePrimaryColor) !important;
    border-radius: 5px;
    box-shadow: 0px 1px 10px 0px rgba(68, 126, 183, 0.25) !important;
}

@media (max-width: 700px) {
    .Container {
        grid-template-columns: 100%;
    }
}