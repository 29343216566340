@import url("../../assets/styles/fonts.css");

.ListingList {
    display: grid;
    grid-template-columns: 130px auto 200px 35px;
    align-items: start;
    gap: 10px;
    background: var(--themePrimaryColor) !important;
    box-shadow: 0px 0px 9px rgba(68, 126, 183, 0.01), 0px 0px 8px rgba(68, 126, 183, 0.05), 0px 0px 6px rgba(68, 126, 183, 0.09), 0px 0px 3px rgba(68, 126, 183, 0.1);
    border-radius: 10px;
    padding: 20px;
    transition: all 0.7s;
    min-height: 135px;
    width: 100%;
    overflow: hidden;
}

.ListingList:hover {
    background: var(--userHighlightColor) !important;
    cursor: pointer;
}

/* .ListingList:hover {
    grid-template-columns: 160px auto 200px 35px;
    transition: all 0.7s;
    min-height: 165px;
    cursor: pointer;
    width: 100%;
} */

@media screen and (min-width: 1400px) {
    .ListingList {
        grid-template-columns: 130px auto 300px 35px;
    }

    /* .ListingList:hover {
        grid-template-columns: 160px auto 300px 35px;
    } */
}

.ListingList img {
    height: auto !important;
    transition: all 0.7s;
    object-fit: fill;
    border-radius: 10px;
}

.Listings_AssetImage {
    height: 100%;
}

.Listings_AssetBlock {
    text-align: left;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    line-height: 1.5;
}

.Listings_AssetBlock>div:first-child {
    font-size: 18px;
    color: var(--userPrimaryColor) !important;
    transition: font-size 0.7s;
    font-family: 'Gilroy-SemiBold' !important;
}

/* .ListingList:hover .Listings_AssetBlock>div:first-child {
    font-size: 22px;
    transition: font-size 0.7s;
} */

.Listings_MakeModel {
    display: flex;
    gap: 5px;
    color: var(--userPrimaryColor) !important;
    transition: font-size 0.7s;
    font-size: 14px;
}

/* .ListingList:hover .Listings_MakeModel {
    font-size: 17px;
    transition: font-size 0.7s;
} */

.Listings_Price {
    color: #4ECBD7 !important;
    background-color: #4ECBD730 !important;
    font-family: 'Gilroy-SemiBold' !important;
    font-size: 22px;
    padding: 5px;
    border-radius: 5px;
    max-width: fit-content;
    line-height: 1;
    display: flex;
}

.Listings_Price_Condition {
    font-size: larger;
}

.Listings_AssetDetailsBlock {
    text-align: left;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    font-family: 'Gilroy-SemiBold' !important;
}

.Listings_AssetDetailsBlock>div:first-child {
    color: gray;
    font-family: 'Gilroy-Regular' !important;
}

.Listings_AssetDetailsBlock>div:last-child {
    display: grid;
    gap: 5px;
}

.Listings_AssetDetailsBlock>div:last-child>div {
    display: flex;
    gap: 10px;
    align-items: center;
}

.Listings_VendorDetailsBlock {
    text-align: left;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
}

.VendorContactName {
    font-family: "Gilroy-SemiBold" !important;
    font-size: larger;
    color: var(--userPrimaryColor) !important;
    line-height: 1.5 !important;
}

.VendorName {
    line-height: 1 !important;
    font-size: medium !important;
}

.VendorActions {
    display: flex;
    gap: 10px;
    margin: 20px 0px 0px;
    width: 100%;
}

.Listings_InteractinsBlock {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.Hide {
    display: none !important;
}

.Quantity {
    font-weight: bold;
    color: var(--userPrimaryColor) !important;
}

.Listings_Condition {
    font-size: 10px;
    color: gray;
    padding: 0px;
}