@import url("../../assets/styles/fonts.css");

.NotificationDetailsBlock {
    box-shadow: 0px 1px 10px 0px rgba(68, 126, 183, 0.25);
    border-radius: 5px;
    background: var(--themePrimaryColor) !important;
    padding: 3%;
    text-align: left;
}

.NotificationDetailsTitle {
    text-transform: uppercase;
    font-weight: bolder;
    padding: 2% 0%;
    font-family: "Gilroy-SemiBold" !important;
}

.NotificationDetailsTitle_LessPadding {
    text-transform: uppercase;
    font-weight: bolder;
    padding: 1% 0%;
    font-family: "Gilroy-SemiBold" !important;
}

.NotificationRow {
    padding: 2% 0%;
}

.NotificationRow_LessPadding {
    padding: 1% 0%;
}