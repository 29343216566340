.SpecialPayment_Container {
    display: grid;
    gap: 15px;
}

.SpecialPayment_Container:not(:last-child) {
    border-right: 1px solid #ccc;
    margin-right: 15px;
    margin-left: 8px;
}

.SpecialPayment_Fields {
    display: grid;
    grid-template-columns: 50% auto;
    gap: 25px;
    text-align: left;
}