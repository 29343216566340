@import url("../../assets/styles/fonts.css");

.LoginScreen {
    display: grid;
    grid-template-columns: auto 570px;
    height: 100vh;
    background-color: #F4F4F4 !important;
    overflow-x: hidden;
    overflow-y: auto;
    font-family: 'Gilroy-Regular' !important;
}

.ImageSection {
    background: url("https://lanternpublicstaticfiles.blob.core.windows.net/main/LoginScreenImage.svg");
    background-size: contain;
    background-position: left;
    background-repeat: repeat-y;
    width: 720px !important;
}

@media screen and (max-width: 1000px) {
    .LoginScreen {
        display: grid;
        grid-template-columns: 100%;
    }

    .ImageSection {
        display: none;
    }

    .LanternLogo {
        width: 100% !important;
        position: absolute;
        bottom: 60px;
    }
}

@media screen and (max-height: 750px) {
    .LanternLogo {
        width: 100% !important;
        position: static !important;
    }
}

.LoginSection {
    line-height: 3;
    padding: 5% 0;
}

.Logo {
    width: 100%;
    margin-top: 2%;
}

.Welcome {
    width: 100%;
    font-weight: bold;
    font-size: x-large;
    margin: 2% 0%;
}

.Form {
    width: 60%;
    min-width: 300px;
    max-width: 500px;
    margin: auto;
}

.VerticalWarning {
    color: red;
    font-size: smaller;
}

.Divider {
    width: 200px !important;
    margin: auto !important;
}

.ForgotPassword {
    text-align: right;
    color: #447EB7;
    cursor: pointer;
    padding-top: 5px;
}

.ForgotPassword:hover {
    /* font-weight: bold; */
    text-decoration: underline;
}

.IconColor {
    color: #447EB7 !important;
    font-size: 16px !important;
}

.LoginButton {
    background-color: #447EB7 !important;
    color: white !important;
    font-weight: bold !important;
    font-size: 16px !important;
    width: 130px !important;
    height: 40px !important;
}

.LoginButton:hover {
    background-color: #CFDCE9 !important;
    color: #447EB7 !important;
    font-weight: bold !important;
    font-size: 16px !important;
    width: 130px !important;
    height: 40px !important;
}

.JoinUsButton {
    background-color: #8ECEAE !important;
    color: white !important;
    font-weight: bold !important;
    font-size: 16px !important;
    width: 130px !important;
    height: 40px !important;
}

.JoinUsButton:hover {
    background-color: #6FACB2 !important;
    color: white !important;
    font-weight: bold !important;
    font-size: 16px !important;
    width: 130px !important;
    height: 40px !important;
}

.dontHaveAccount {
    line-height: 2 !important;
    font-weight: bold;
}

.LanternLogo {
    width: calc(100% - 570px);
    position: absolute;
    bottom: 60px;
}