.content-style {
    margin: 0 16px;
}

.breadcrumb-style {
    margin: 16px 0;
    display: none;
}

.content-header {
    font-weight: bold;
    font-size: 22px;
    text-align: left;
    padding-bottom: 3%;
    color: var(--themeFontColor) !important;
    font-family: 'Gilroy-SemiBold', sans-serif !important;
}

.home-content {
    margin: 3% 1%;
    padding: 24px;
    min-height: 360px;
}

@media (max-width: 770px) {
    .home-content {
        margin: 3% 0%;
        padding: 24px 0px;
        min-height: 360px;
    }

    .content-style {
        margin: 0 8px;
    }
}

.HomeLayout_Content {
    overflow: clip auto;
    max-height: calc(89vh - 63px);
    scroll-behavior: smooth;
    padding-right: 10px;
    padding-bottom: 85px;
}

@media screen and (max-width: 770px) {
    .HomeLayout_Content {
        max-height: calc(87vh - 170px);
    }
}

@media screen and (max-width: 770px) {
    .HomeLayout_Content {
        max-height: calc(85vh - 200px);
    }
}

.HomeLayout_Content::-webkit-scrollbar {
    width: 5px;
}

.HomeLayout_Content::-webkit-scrollbar-track {
    background: transparent;
}

.HomeLayout_Content::-webkit-scrollbar-thumb {
    background: darkgray !important;
    /* background: var(--userHighlightColor) !important; */
    border-radius: 10px;
    border: 0px solid #F4F4F4;
}