.DrawdownContainer {
    display: grid;
    row-gap: 30px;
    overflow-x: hidden;
    background: var(--themePrimaryColor) !important;
    border-radius: 5px;
    box-shadow: 0px 1px 10px 0px rgba(68, 126, 183, 0.25);
    padding: 2%;
}

.TabContent {
    overflow: auto;
    width: inherit;
}

.AdditionalDetails {
    display: grid;
    grid-template-columns: 40% 40%;
    column-gap: 20%;
    margin: 5% 0% 0%;
}

.Hide {
    display: none;
}

.AdditionalDetails_Details {
    display: grid;
    grid-template-columns: 50% 50%;
    background: white;
    border-radius: 5px;
    box-shadow: 0px 1px 10px 0px rgba(68, 126, 183, 0.25);
    padding: 5% 0%;
    row-gap: 10px;
    height: calc(100% + 32%);
}

.AdditionalDetails_Details2 {
    display: grid;
    grid-template-columns: 50% 50%;
    background: white;
    border-radius: 5px;
    box-shadow: 0px 1px 10px 0px rgba(68, 126, 183, 0.25);
    padding: 5% 0%;
    row-gap: 10px;
    height: calc(100% - 32%);
}

.GridDetails {
    display: grid;
    grid-template-columns: 50% 50%;
    min-height: fit-content;
}

.GridDetails_Header {
    text-align: justify;
    padding-left: 30%;
    font-weight: bold;
}

.GridDetails_Value {
    text-align: justify;
    padding-left: 5%;
    font-weight: bold;
    min-height: fit-content;
}

.RequirementsContainer {
    background: white;
    border-radius: 5px;
    box-shadow: 0px 1px 10px 0px rgba(68, 126, 183, 0.25);
    padding: 5%;
    text-align: justify;
}

.RequirementsContainer_Header {
    font-weight: bold;
    font-size: large;
}

.RequirementsContainer_Item {
    padding-top: 2%;
    font-weight: bold;
}

.AlertBlock {
    display: block;
    margin-bottom: 2%;
}

.AlertContent {
    text-align: left;
    margin: 15px 0px;
}

.Alert {
    display: grid;
    grid-template-columns: 55px auto;
    background-color: #CFDCE950;
    border-radius: 10px;
}

.WarningAlert {
    margin-bottom: 2%;
    text-align: left;
    font-weight: bold;
}

@media(max-width: 770px) {
    .AdditionalDetails {
        display: grid;
        grid-template-columns: 100%;
        row-gap: 35%;
        margin: 5% 0% 0%;
    }

    .AdditionalDetails_Details {
        display: grid;
        grid-template-columns: 100%;
        background: white;
        border-radius: 5px;
        box-shadow: 0px 1px 10px 0px rgba(68, 126, 183, 0.25);
        padding: 2%;
        height: calc(100% + 32%);
        row-gap: 5%;
    }
}

.DrawdownDetails {
    background: var(--themePrimaryColor) !important;
    border-radius: 5px;
    margin-top: 2%;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(18rem, 1fr));
    grid-gap: 1rem;
}

.DisabledLink {
    border-bottom: 2px solid var(--userHighlightColor) !important;
    box-shadow: none;
    cursor: not-allowed;
    opacity: 1;
    text-align: left;
    padding: 1% 1%;
    font-size: 16px;
    font-family: 'Gilroy-Regular' !important;
    height: 35px;
}

.FlexBetween {
    display: flex;
    justify-content: space-between;
}

.AlertIcon {
    color: #FF5555;
    font-size: 28px;
    margin: 15px;
}