.requestAmendmentButton {
    width: 25%;
    bottom: 64%;
    font-weight: bold;
    font-size: large;
}


@media (min-width: 800px) {
    .requestAmendmentButton {
        width: 15%;
        min-width: fit-content;
        bottom: 72%;
        right: 5%;
        font-weight: 600;
        font-size: large;
    }
}

.RequestAmendment_Header {
    font-weight: 700;
    color: #898989;
    padding-bottom: 3%;
    font-size: medium;
}

.RequestAmendment_Helper {
    padding: 3% 0%;

}

.AmendmentRequestedButton {
    background-color: green;
}

.AmendmentRequestedButton:hover {
    background-color: rgb(0, 128, 0, 0.8) !important;
}