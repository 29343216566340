.paymentScheduleFormStyle {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(18rem, 1fr));
}

.specialPaymentStyle {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(18rem, 1fr));
    margin: 0% 2%;
}

.paymentScheduleFieldStyle {
    padding: 0% 2%;
}

.Header {
    margin: 2%;
    text-align: left;
    font-size: x-large;
}