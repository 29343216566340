.container {
    background: var(--themePrimaryColor) !important;
    width: 100vw;
    height: 100vh;
    color: var(--themeFontColor) !important;
}

.layout {
    display: grid;
    grid-template-columns: 50% 50%;
    height: 100vh;
    background: var(--themePrimaryColor) !important;
}

.leftLayout {
    display: flex;
    align-items: center;
    text-align: start;
    margin-left: 30%;
}

.rightLayout {
    display: flex;
    align-items: center;
}

.errorCode {
    font-weight: bolder;
    font-size: 80px;
    color: var(--userPrimaryColor) !important;
}

.errorCode-mobile {
    font-weight: bolder;
    font-size: 50px;
    color: var(--userPrimaryColor) !important;
}

.notFound {
    font-weight: bold;
    font-size: 40px;
}

.notFound-mobile {
    font-weight: bold;
    font-size: 25px;
    padding-bottom: 15%;
    color: var(--themeFontColor) !important;
}

.errorMessage {
    font-weight: 400;
    font-size: 26px;
    color: grey;
}

.errorMessage-mobile {
    font-weight: 400;
    font-size: 18px;
    color: grey;
    padding-top: 15%;

}

.buttonContainer {
    padding: 20% 0%;
}

.button {
    width: 60%;
}

.homeButton {
    background: var(--userPrimaryColor) !important;
    color: white;
    font-weight: bold;
    width: 60%;
    height: 50px;
}

.homeButton:hover {
    background: var(--userHighlightColor) !important;
    color: var(--userPrimaryColor) !important;
}