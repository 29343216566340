.Container {
    border-radius: 0px 0px 8px 8px;
    padding: 5%;
    background-color: var(--themePrimaryColor);
    color: var(--themeFontColor);
}

.InputContainer {
    padding: 5px;
    border-radius: 0px 0px 4px 4px;
    width: 100%;
}

.FlexCenter {
    display: flex;
    justify-content: center;
}

.RowContent {
    display: grid;
    grid-template-columns: repeat(2, 2fr);
    grid-gap: 5%;
}

@media (max-width: 800px) {
    .RowContent {
        grid-template-columns: repeat(1, 1fr) !important;
        grid-gap: 0% !important;
    }
}

.FlexCenterMargin {
    display: flex;
    justify-content: center;
    margin: 5% 0% 1%;
}

.FlexCenterMargin2 {
    display: flex;
    justify-content: center;
    margin: 2% 0%;
}

.CalculatedInput {
    margin: 2% 0%;
    height: 50px;
}

.CalculateButton {
    margin: 12% 0%;
}

.CalculateButton:hover {
    color: var(--userPrimaryColor) !important;
}