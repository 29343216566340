.FilterDivider {
    margin: 5px 0px;
}

.Actions {
    display: flex;
    justify-content: space-between;
    padding: 5px 0px;
}

.ActionButtons {
    min-width: fit-content;
    width: 70px;
}