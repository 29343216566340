@import url("../../assets/styles/fonts.css");

.item {
    overflow: hidden;
    display: flex;
    width: 470px;
    height: 85px;
    align-items: center;
    border-bottom: 2px solid darkgray;
    font-family: 'Gilroy-Regular';
}

.icon {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 75px;
    height: inherit;
    border-radius: inherit;
    overflow: hidden;
}

.item:hover .icon {
    animation: slideOutHide 1s forwards;
    opacity: 0;
}

/* .item:not(:hover) .icon {
    animation: slideOutShow 2s forwards;
    opacity: 1;
} */

/* .item.item-hovered:not(:hover) .icon, */
.item.item-hovered-out:not(:hover) .icon {
    animation: slideOutShow 1s forwards;
    opacity: 1;
}

.content {
    width: 395px;
    height: inherit;
    border-radius: inherit;
    overflow: hidden;
    display: grid;
    grid-template-columns: 75% 25%;
}

.item:hover .content {
    width: 320px;
}

.view,
.dismiss {
    display: none;
    height: 75px;
    border-radius: inherit;
}

.item:hover .view,
.item:hover .dismiss {
    display: block;
    animation: slideOutShow 1s forwards;
    opacity: 1;
}

@keyframes slideOutHide {
    from {
        opacity: 1;
        width: 75px;
        transform: translateX(0%);
    }

    to {
        opacity: 0;
        transform: translateX(-100%);
        width: 0px;
    }
}

@keyframes slideOutShow {

    from {
        opacity: 0;
        transform: translateX(-100%);
        width: 0px;
    }

    to {
        opacity: 1;
        width: 75px;
        transform: translateX(0%);
    }

}

.timestamp {
    font-family: 'Gilroy-Regular';
    font-size: 13px;
    color: darkgray;
    text-align: right;
    margin: auto 5%;
    white-space: nowrap;
}

.details {
    margin: auto 2%;
}

.headerText {
    font-weight: bold;
    font-size: 16px;
    white-space: nowrap;
    color: var(--userPrimaryColor) !important;
}

.subText {
    font-size: small;
    color: var(--themeFontColor) !important;
}

.emptyContainer {
    width: 390px;
}

.viewAllContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 40px;
}

.emptyContainer .viewAllContainer {
    border-top: 2px solid lightgray;
}

.ListContainer {
    max-height: 385px;
    overflow-y: auto;
}

.ListContainer::-webkit-scrollbar {
    width: 8px;
}

.ListContainer::-webkit-scrollbar-track {
    background: transparent;
    border-radius: 0px 4px 4px 0px;
}

.ListContainer::-webkit-scrollbar-thumb {
    background-color: darkgray;
    border-radius: 20px;
    border: 2px solid white;
}

.ViewAllButton {
    color: var(--userPrimaryColor) !important;
    font-family: "Gilroy-SemiBold" !important;
}

.ViewAllButton:hover > span {
    text-decoration: underline !important;
}