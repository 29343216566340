.Form {
    min-height: fit-content;
}

.FormLayout {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 5px 50px;
}

.Header {
    font-size: 22px;
    font-weight: bold;
    color: black;
    margin: 2% 0%;
    text-align: left;
}

@media screen and (max-width: 768px) {

    .Form {
        min-height: fit-content;
    }

    .FormLayout {
        grid-template-columns: repeat(1, 1fr);
    }

    .FormLayout_Merge {
        grid-column: 1 / span 1 !important;
    }
}

.FormLayout_Merge {
    grid-column: 1 / span 2;
}

.MaxChar {
    text-align: right;
    color: #447EB7;
}

.SendRequest {
    background: #447EB7 !important;
    color: white !important;
    border-radius: 10px;
    min-width: fit-content;
    width: 200px;
    height: 45px;
    font-weight: bold;
    font-size: 18px;
    margin: 5% 0% 2%;
}

.SendRequest:hover {
    background: #CFDCE9 !important;
    color: #447EB7 !important;
    border-radius: 10px;
    min-width: fit-content;
    width: 200px;
    height: 45px;
    font-weight: bold;
    font-size: 18px;
}

.CancelRequest {
    text-decoration: underline;
    color: #447EB7 !important;
    cursor: pointer;
}

.CancelRequest:hover {
    font-weight: bold;
}

.VerticalWarning {
    color: red;
    font-size: smaller;
}