.mainLayout-container {
    min-height: 100vh !important;
}

.mainLayout-header {
    height: 5vh;
    position: 'sticky';
    top: 0;
    z-index: 1;
}

.App_Header {
    background-color: var(--themePrimaryColor) !important;
    padding-inline: 0px !important;
}

.mainLayout-content {
    background-color: white;
    box-shadow: 0px 1px 10px 0px rgba(68, 126, 183, 0.25);
    border-radius: 10px;
    width: auto;
    margin: 1.5rem;
    padding: 0.5rem;
}

.mainLayout-footer {
    height: 5vh;
    position: 'sticky';
    bottom: 0;
    z-index: 1;
    text-align: end;
}

.MainLayout-content-container-all {
    /* overflow-y: scroll; */
    /* max-height: 90vh; */
    max-height: calc(100vh - 64px);
}

.MainLayout-content-container {
    overflow-y: scroll;
    max-height: calc(100vh - 64px);
    /* max-height: 89vh; */
}

.MainLayout-content-container::-webkit-scrollbar {
    width: 6px;
}

.MainLayout-content-container::-webkit-scrollbar-track {
    background: transparent;
    /* background: #f5f5f5; */
}

.MainLayout-content-container::-webkit-scrollbar-thumb {
    background-color: darkgrey;
    border-radius: 20px;
}

.divStyle {
    background-repeat: no-repeat;
    background-position: right;
    background-size: contain;
    height: 100%;
}

.footerStyle {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    height: fit-content;
    max-height: fit-content;
    background-color: var(--userPrimaryColor) !important;
    color: white;
    padding: 5px 15px;
    position: fixed;
    bottom: 0;
    width: -webkit-fill-available;
}

.Hide {
    display: none;
}