@import url("../../assets/styles/fonts.css");

.ThemePreference_Container {
    display: flex;
    gap: 5%;
    margin-top: 20px;
}

.OuterContainer,
.OuterContainer_Selected {
    padding: 7px;
    border-radius: 12px;
    max-width: 300px;
}

.OuterContainer_Selected {
    /* outline: 4px solid red !important; */
    outline: 4px solid var(--userPrimaryColor) !important;
}

.OuterContainer {
    outline: 4px solid transparent;
    cursor: pointer;
}

.InnerContainer {
    background: var(--themePrimaryColor) !important;
    color: var(--themeFontColor) !important;
    border-radius: 10px;
    box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.25);
}

.InnerContainer_Header {
    background: var(--themeSecondaryColor) !important;
    padding: 10px 15px;
    font-family: 'Gilroy-SemiBold' !important;
    border-radius: 10px 10px 0px 0px;
}

.InnerContainer_Content {
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 25px;
    font-size: 15px;
}

@media screen and (min-width: 501px) and (max-width: 960px) {
    .InnerContainer_Content {
        padding: 10px;
        gap: 0px;
    }

    .OuterContainer,
    .OuterContainer_Selected {
        padding: 7px;
        border-radius: 12px;
        max-width: calc(50% - 10px);
    }
}

@media screen and (max-width: 500px) {
    .ThemePreference_Container {
        flex-direction: column;
    }
}