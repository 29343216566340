@import url("../../assets/styles/fonts.css");

.Container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 30px 10px;
    gap: 10px;
    height: 75%;
    min-height: fit-content;
}

.SubContainer {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.ContentLine1 {
    font-family: 'Gilroy-SemiBold' !important;
}