@import url("../../assets/styles/fonts.css");

.Registration_Layout {
    min-height: 100vh;
    font-family: 'Gilroy-Regular' !important;
}

.Registration_Content {
    /* Top Left-Right Bottom */
    margin: 3% 5% 5%;
}

.Registration_InnerLayout {
    background: white;
    min-height: fit-content;
    border-radius: 5px;
    box-shadow: 2px 2px lightgrey;
    overflow-y: scroll !important;
    min-height: 85vh !important;
    height: 250px !important;
}

@media (max-width: 770px) {
    .Registration_InnerLayout {
        min-height: 90vh !important;
    }
}

.Registration-Header {
    display: flex;
    justify-content: center;
    font-size: 200%;
    font-weight: 600;
    color: #447EB7;
    min-width: fit-content;
    margin: 1%;
}

.Registration_Steps {
    width: 50%;
    min-width: fit-content;
    margin: 5% auto 2%;
}

.Buttons {
    width: 20%;
    min-width: fit-content;
    height: 35px;
    font-size: medium;
}