.header {
    text-align: left;
    padding-left: 1%;
    font-weight: bold;
    font-size: larger;
    color: var(--themeFontColor) !important;
}

.FileList {
    display: flex;
    align-items: center;
    font-weight: bold;
}

.FileList>div {
    padding-right: 20px;
}