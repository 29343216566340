.selectedColor {
    color: var(--userPrimaryColor) !important;
}

.Container {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    gap: 10px 0px;
    margin: 10px 0px;
    max-height: 350px;
    overflow-y: auto;
    scroll-behavior: smooth;
}

.Container::-webkit-scrollbar {
    width: 8px;
}

.Container::-webkit-scrollbar-track {
    background: var(--themeSecondaryColor) !important;
}

.Container::-webkit-scrollbar-thumb {
    background: var(--userPrimaryColor) !important;
    border-radius: 10px;
    border: 1px solid #F4F4F4;
}

.IndividualReq {
    display: flex;
    align-items: center;
    border-bottom: 1px solid darkgray;
    padding: 5px 10px;
}