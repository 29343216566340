@import url("../../assets/styles/fonts.css");

.Header {
    font-family: 'Gilroy-SemiBold' !important;
    font-size: 22px;
}

.SubHeader {
    font-family: 'Gilroy-UltraLight' !important;
    font-size: medium;
}

.headerTitle {
    color: gray;
    padding-left: 5px;
}

.browseButton {
    margin-top: 2%;
    border: 1px solid var(--userHighlightColor) !important;
    color: var(--userPrimaryColor) !important;
}

.browseButton:hover {
    text-shadow: 0 0 0.25px currentcolor;
    border: 1px solid var(--userPrimaryColor) !important;
}

.blockContainer {
    background: var(--themePrimaryColor) !important;
    padding: 24px;
    min-height: 400px;
    border-radius: 5px;
    box-shadow: 0px 1px 10px 0px rgba(68, 126, 183, 0.25);
    margin-top: 2%;
}

.uploadHeader {
    text-align: left;
    font-weight: bolder;
    font-size: large;
    margin-bottom: 2%;
}

.uploadGrid {
    display: grid;
    grid-template-columns: 40% 60%;
    margin: auto;
}

.requirementChecklist {
    margin: 0% 5%;
    text-align: left;
    padding: 0% 1%;
}

.Modal_Actions {
    display: flex;
    justify-content: space-between;
    margin-top: 25px;
}

.Modal_Submit {
    height: 40px;
    width: 150px;
}

.Modal_Submit:disabled {
    background-color: var(--themeSecondaryColor) !important;
    color: darkgray !important;
}

.Modal_Cancel {
    height: 40px;
    width: 150px;
    outline: 2px solid var(--userHighlightColor) !important;
    background-color: white !important;
    color: #FF5555 !important;
}

.Modal_Cancel:hover {
    background-color: #FF5555 !important;
    color: white !important;
    outline: none !important;
}