/* SVG Sales Icon CSS */
.Sales:hover .Sales_BG,
.Sales_Selected {
  fill: var(--userHighlightColor) !important;
}

.Sales_Fill {
  fill: var(--userPrimaryColor) !important;
}

.Sales_Stroke {
  stroke: var(--userPrimaryColor) !important;
}