.interestedOuter {
    fill: var(--userPrimaryColor) !important;
}

.defaultOuter {
    fill: var(--userPrimaryColor) !important;
}

.interestedInner {
    fill: var(--userPrimaryColor) !important;
}

.defaultInner {
    fill: transparent !important;
}

.InterestedIcon:hover .defaultInner {
    fill: var(--userPrimaryColor) !important;
}

.hovered {
    fill: var(--userPrimaryColor) !important;
}

.notHovered {
    fill: var(--themePrimaryColor) !important;
}