.SalesIcon {
  height: 60px;
}

.SalesContent {
  position: relative;
  width: 250px;
  padding: 5%;
  text-align: center;
  background-color: var(--themePrimaryColor) !important;
  border-radius: 0px 0px 10px 10px;
}

.ContentHeader {
  position: relative;
  height: 55px;
  z-index: 1;
}

.ContentAvatar {
  position: relative;
  margin: 0% 0% 0% 7%;
}

.AvatarBG {
  background-color: lightgray;
}

.SalesName {
  font-weight: bolder;
  font-size: 21px;
  color: var(--themeFontColor) !important;
}

.SalesPosition {
  color: var(--themeFontColor) !important;
  font-size: 14px;
}

.FlexCenterMargin {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 5px;
}

.MarginLeft {
  margin-left: 10px;
  color: var(--userPrimaryColor) !important;
  text-decoration: underline;
}

.CompanyLogo {
  width: 9%;
}

.HeaderSales_AdditionalContent {
  margin-top: 10px;
  background: var(--themePrimaryColor) !important;
  box-shadow: 0px 1px 10px 0px rgba(68, 126, 183, 0.25);
  border-radius: 10px;
  padding: 10px 20px;
  width: 210px !important;
  font-size: 13px;
}