.listContainer {
    margin: 2% 0%;
    padding-right: 4%;
}

.listContainerGrid {
    display: grid;
    grid-template-columns: 15% 85%;
}

.listIcon {
    display: flex;
    align-items: center;
    justify-content: center;
}

.listDetails {
    display: grid;
    grid-template-columns: 85% 15%;
}

.fileName {
    color: var(--themeFontColor) !important;
    font-weight: bold;
}