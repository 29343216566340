.container {
    border-radius: 4px !important;
    background: var(--themePrimaryColor) !important;
    box-shadow: 0px 1px 10px 0px rgba(68, 126, 183, 0.25);
    padding: 2% 2% 5%;
}

.Hide {
    display: none;
}

.AlertBlock {
    display: block;
    margin-bottom: 2%;
}

.AlertContent {
    text-align: left;
    margin: 15px 0px;
}

.Alert {
    display: grid;
    grid-template-columns: 55px auto;
    background-color: #CFDCE950;
    border-radius: 10px;
}

.AlertIcon {
    color: #FF5555;
    font-size: 28px;
    margin: 15px;
}