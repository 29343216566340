@import url("../../assets/styles/fonts.css");

.Container {
    font-size: 18px;
    line-height: 1.5;
    text-align: left;
}

.InnerContainer {
    background: var(--themePrimaryColor) !important;
    color: var(--themeFontColor) !important;
    border-radius: 10px;
    box-shadow: 0px 1px 10px 0px rgba(68, 126, 183, 0.25);
    margin: 10px 0px 10px 5px;
    padding: 20px;
}

.InnerContainer_Header {
    font-family: 'Gilroy-SemiBold' !important;
}

.InnerContainer_SubHeader {
    font-family: 'Gilroy-UltraLight' !important;
}

.Settings_Dragger {
    margin: 3% 0%;
}

.Settings_Dragger a {
    color: var(--userPrimaryColor) !important;
    text-decoration: underline;
}

.Settings_DraggerContent {
    font-weight: bold;
    margin-top: 2%;
}

.Settings_ActionsSection {
    margin: 2% auto;
    display: flex;
    justify-content: space-around;
    width: 50%;
    min-width: fit-content;
}

.Settings_SaveButton {
    min-width: fit-content;
    width: 160px;
    height: 40px;
}

.Settings_ResetButton {
    min-width: fit-content;
    width: 160px;
    height: 40px;
    background: var(--userSecondaryColor) !important;
    color: white !important;
}

.Settings_ResetButton:hover {
    background: var(--userSecondaryHoverColor) !important;
    color: white !important;
}