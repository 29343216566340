.header-layout {
    display: grid;
    grid-template-columns: calc(100% - 275px) 69px 69px 69px 69px !important;
    min-width: fit-content;
}

@media (max-width: 770px) {
    .header-layout {
        grid-template-columns: 36% 16% 16% 16% 16% !important;
        white-space: nowrap;
        text-align: right;
    }
}

.Show {
    display: flex;
}

.Hide {
    display: none;
}

.Header_CloseBtn {
    display: none;
}

.Header_Empty {
    display: flex;
}

@media screen and (max-width: 575px) {
    .Header_CloseBtn {
        display: flex;
    }

    .Header_Empty {
        display: none;
    }
}