.ColorPreferences_Container {
    display: grid;
    /* flex-direction: row; */
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 20px;
    margin-top: 20px;
}

.InnerContainer {
    background: var(--themePrimaryColor) !important;
    color: var(--themeFontColor) !important;
    border-radius: 10px;
    box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.25);
    max-width: 300px;
}

.InnerContainer_Header {
    background: var(--themeSecondaryColor) !important;
    padding: 10px 15px;
    font-family: 'Gilroy-SemiBold' !important;
    border-radius: 10px 10px 0px 0px;
}

.InnerContainer_Content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
    padding: 25px;
    font-size: 15px;
}

.ColorPreferences_Picker {
    width: fit-content;
    border: 4px solid darkgray;
    border-radius: 50%;
}

.Preview {
    width: 100%;
    font-family: 'Gilroy-UltraLight' !important;
}

