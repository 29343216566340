.row1, .row3 {
    display: grid;
    grid-template-columns: 48% 48%;
    grid-gap: 2rem;
    margin: 3% 0%;
}

.row2 {
    display: grid;
    grid-template-columns: 65% 31%;
    grid-gap: 2rem;
    margin: 3% 0%;
}

@media (max-width: 770px) {
    .row1, .row2, .row3 {
        grid-template-columns: repeat(1, 1fr) !important;
        margin: 0%;
        grid-gap: 0rem;
    }
}