@import url("../../assets/styles/fonts.css");

.rightLayout {
    justify-content: flex-start; 
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    margin: auto;
    margin-top: 25px;
    margin-bottom: 25px;
    font-family: 'Gilroy-SemiBold', sans-serif !important;
}

.rightLayout h1 {
    font-size: 24px;
    margin-bottom: 10px;
}

.rightLayout p {
    font-size: 16px;
    margin-bottom: 20px;
}

.emailInput {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 20px;
    background: var(--themePrimaryColor) !important;
    color: var(--themeFontColor) !important;
}

.emailInput label {
    font-size: 16px;
    margin-bottom: 5px;
}

.resetPasswordButton {
    width: 20%;
    height: 35px;
    min-width: fit-content;
    background: #447EB7;
    font-weight: bold;
}

.resetPasswordButton:hover {
    background: #CFDCE9 !important;
    color: #447EB7 !important;
}

.Hovered:disabled {
    background: var(--themePrimaryColor) !important;
}

.passwordInput {
    background-color: var(--themePrimaryColor) !important;
    color: var(--themeFontColor) !important;
}

/* Apply background color to the input itself */
.passwordInput.ant-input-outlined {
    background-color: var(--themePrimaryColor) !important;
    color: var(--themeFontColor) !important;
}


