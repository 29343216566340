.GreenTags {
    color: var(--userSecondaryColor) !important;
    background: #8ECEAE40 !important;
}

.NeutralTags {
    color: #4ECBD7 !important;
    background: #4ECBD740 !important;
}

.RedTags {
    color: #FF5555 !important;
    background: #FF555540 !important;
}