.ListItem,
.ListItemSelected {
    display: grid;
    grid-template-columns: 30% 70%;
    padding: 5%;
}

.ListItem:hover,
.ListItemSelected {
    font-weight: bold;
}

.Content {
    width: 250px;
    border-radius: 0px 0px 10px 10px;
    cursor: pointer;
    color: var(--userPrimaryColor) !important;
    background: var(--themePrimaryColor) !important;
}

.FlexCenter {
    display: flex;
    justify-content: center;
    align-items: center;
}

.FontPadding {
    font-size: medium;
    padding-left: 5%;
}

.ProfileIcon {
    height: 60px;
}