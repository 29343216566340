.redTag {
    width: 60%;
    min-width: fit-content;
    white-space: nowrap;
    color: #FF5555;
    background-color: #FF555520;
    text-align: center;
    border: none;
    padding: 3px 10px;
    border-radius: 5px;
}

.greenTag {
    width: 60%;
    min-width: fit-content;
    white-space: nowrap;
    color: #8ECEAE;
    background-color: #8ECEAE40;
    text-align: center;
    border: none;
    padding: 3px 10px;
    border-radius: 5px;
}