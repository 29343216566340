.ReopenButton {
    background-color: var(--userPrimaryColor) !important;
    height: 40px;
    box-shadow: none !important;
}

.ReopenButton:hover {
    background-color: var(--userHighlightColor) !important;
    color: var(--userPrimaryColor) !important;
}

.TitleFlex {
    display: flex;
}

.TitleHeader {
    padding-left: 5px;
}

.SubHeader {
    font-size: 14px;
}

.ModalActions {
    display: flex;
    justify-content: space-between;
}

.Error {
    color: red;
}

.ActionButton {
    height: 40px;
    margin-right: 10px;
    background-color: #FF5555;
}

.Close {
    color: grey;
}

.Close:hover {
    color: black;
    transition: all 0.3s;
}