.GetStarted {
    background: #F4F4F4 !important;
    max-width: 100%;
    padding: 30px;
    height: calc(100% - 60px);
    overflow: auto;
}

.HeaderContainer {
    display: flex;
    gap: 30px;
}

.Logo {
    width: 30px;
}

.Header {
    color: var(--userPrimaryColor) !important;
    font-weight: bold;
    font-size: xx-large;
}

.HeaderSpan {
    color: black !important;
}

.SubHeader {
    color: black !important;
    font-weight: bold;
    display: flex;
    justify-content: left !important;
    margin-top: 30px;
}

.SubHeader_Text {
    color: black !important;
    display: flex;
    justify-content: left !important;
    margin-bottom: 30px;
}

.GetStarted_Container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 30px 5%;
}

@media screen and (max-width: 1280px) {
    .GetStarted_Container {
        grid-template-columns: repeat(2, 1fr);
        gap: 30px 5%;
    }
}

@media screen and (max-width: 900px) {

    .Header {
        color: var(--userPrimaryColor) !important;
        font-weight: bold;
        font-size: x-large;
    }

    .GetStarted_Container {
        grid-template-columns: repeat(1, 1fr);
        gap: 30px 5%;
    }
}

.GetStarted_Card {
    background: #FFFFFF;
    box-shadow: 0px 1px 10px 0px rgba(68, 126, 183, 0.25);
    border-radius: 10px;
    padding: 10%;
}

.Card_Image {
    max-width: -webkit-fill-available;
}

.Card_Title {
    color: var(--userPrimaryColor) !important;
    font-weight: bold;
    font-size: 21px;
}

.Action {
    margin: 30px;
}

.ContinueButton {
    width: 180px;
    height: 50px;
    font-size: large;
    font-weight: bold;
}