.Pie>div>canvas {
    width: 100% !important;
}

.dealsLabel {
    font-weight: 600;
    color: var(--themeFontColor) !important;
    font-family: "Gilroy-Regular";
    font-size: 14px;
}

.dealsPercentage {
    font-weight: normal;
    color: var(--themeFontColor) !important;
    font-family: "Gilroy-Regular";
    font-size: small;
}

.header {
    font-weight: bold;
    font-size: x-large;
    text-align: left;
    padding: 2%;
    color: var(--themeFontColor) !important;
}

.Empty {
    display: grid;
    margin: auto;
    margin-top: 40%;
}

.Hide {
    display: none;
}

@media screen and (max-width: 800px) {
    .Empty {
        display: grid;
        margin: auto;
        margin-top: 15%;
    }
}

.Container {
    height: calc(96% - 30px);
    display: flex;
    align-items: center;
    justify-content: center;
}