.Container {
    background: var(--themePrimaryColor) !important;
    border-radius: 4px !important;
    box-shadow: 0px 1px 10px 0px rgba(68, 126, 183, 0.25) !important;
    padding-top: 2%;
    padding: 15px 15px 15px !important;
}

.Container-Tasks {
    background: var(--themePrimaryColor) !important;
    border-radius: 4px !important;
    box-shadow: 0px 1px 10px 0px rgba(68, 126, 183, 0.25) !important;
    padding: 3.5% 4% 4% !important;
}


.Container2 {
    background: var(--themePrimaryColor) !important;
    border-radius: 4px !important;
    box-shadow: 0px 1px 10px 0px rgba(68, 126, 183, 0.25) !important;
    padding: 15px 15px 15px !important;
    height: 98%;
    max-height: 84vh;
    overflow: hidden;
}

.Container2:hover {
    overflow-y: visible;
}

.Container2::-webkit-scrollbar {
    width: 12px;
    /* width of the entire scrollbar */
}

.Container2::-webkit-scrollbar-track {
    background: #F4F4F4;
}

.Container2::-webkit-scrollbar-thumb {
    background-color: #A9A9A9;
    border-radius: 10px;
    border: 3px solid #F4F4F4;
}

.Container4 {
    background: var(--themePrimaryColor) !important;
    border-radius: 4px !important;
    box-shadow: 0px 1px 10px 0px rgba(68, 126, 183, 0.25) !important;
    padding: 15px 15px 15px !important;
    height: 100%;
    overflow-y: hidden;
    margin-bottom: 20px;
}

.TransparentContainer {
    background: transparent !important;
    border-radius: 4px !important;
    box-shadow: none !important;
    /* padding: 2%; */
    padding: 15px 0px 0px !important;
    height: 100%;
}

.Home_Grid1 {
    display: grid;
    grid-template-columns: 65% 35% !important;
    gap: 2% !important;
}

.Home_Grid2 {
    display: grid;
    grid-template-columns: 60% 40% !important;
    gap: 2% !important;
    margin-top: 2%;
    min-height: 30vh;
}

.Home_Grid3 {
    display: grid;
    grid-template-columns: 35% 65% !important;
    gap: 2% !important;
    margin-top: 2%;
}

.Home_Grid3_Internal {
    display: grid;
    grid-template-columns: 100% !important;
    height: 98% !important;
    gap: 2% !important;
}

@media screen and (max-width: 1000px) {

    .Container {
        min-width: 100% !important;
    }

    .Container2 {
        min-width: 100% !important;
    }

    .Home_Grid1 {
        display: grid !important;
        grid-template-columns: 1fr !important;
        gap: 25px !important;
    }

    .Home_Grid2 {
        display: grid !important;
        grid-template-columns: 1fr !important;
        gap: 25px !important;
        margin-top: 25px;
    }

    .Home_Grid3 {
        display: grid !important;
        grid-template-columns: 100% !important;
        gap: 25px !important;
        margin-top: 25px;
    }
}