.smooth-progress-bar {
    transition: background-color 3000ms ease-in-out;
}

.current-size {
    text-align: left;
    margin-top: 8px;
    color: #898989;
}

.Hide{
    display: none;
}