.CreateButton {
    background: var(--userPrimaryColor) !important;
    width: fit-content;
    display: block;
    height: 40px;
}

.CreateButton:hover {
    background: var(--userHighlightColor) !important;
    color: var(--userPrimaryColor) !important;
}

.SecondaryButton {
    background: var(--userSecondaryColor) !important;
    min-width: fit-content;
    width: 100%;
    display: block;
    height: 40px;
}

.SecondaryButton:hover {
    background: var(--userSecondaryHoverColor) !important;
    color: #FFFFFF !important;
}