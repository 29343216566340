@import url("../../assets/styles/fonts.css");

.ListingCard {
    margin: 5px;
    padding: 20px;
    background: var(--themePrimaryColor) !important;
    box-shadow: 0px 0px 9px rgba(68, 126, 183, 0.01), 0px 0px 8px rgba(68, 126, 183, 0.05), 0px 0px 6px rgba(68, 126, 183, 0.09), 0px 0px 3px rgba(68, 126, 183, 0.1);
    border-radius: 10px;
    width: 100% !important;
    max-width: 450px !important;
}

.ListingCard:hover {
    background: var(--userHighlightColor) !important;
    cursor: pointer;
}

.ListingCard_AssetInfo {
    display: grid;
    grid-template-columns: 130px auto;
    gap: 20px;
    height: 110px;
    transition: all 0.5s;
}

.New_Listings_AssetImage {
    min-width: 100%;
    max-width: 450px;
    aspect-ratio: 16/9;
    transition: all 0.5s;
    border-radius: 5px;
    margin-bottom: 10px;
    height: 350px;
}

.ListingCard:hover .ListingCard_AssetInfo {
    grid-template-columns: 150px auto;
    transition: all 0.5s;
}

.ListingCard_AssetInfo img {
    max-width: 130px !important;
    min-height: 70px;
    max-height: 90px !important;
    border-radius: 10px;
    transition: all 0.5s;
}

.ListingCard:hover .ListingCard_AssetInfo img {
    min-height: 70px;
    max-height: 90px !important;
    transform: scale(1.15);
    transition: all 0.5s;
}

.Listings_Description {
    display: flex;
    flex-direction: column;
    text-align: left;
}

.Listings_Description>div:first-child {
    font-family: "Gilroy-SemiBold" !important;
    font-size: larger;
    color: var(--userPrimaryColor) !important;
}

.Listings_MakeModel {
    display: flex;
    gap: 5px;
    color: var(--userPrimaryColor) !important;
}

.Listings_AmountBlock {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 5px;
    color: #898989 !important;
    font-size: 13px !important;
    margin: 10px 0px 0px;
}

@media screen and (max-width: 400px) {
    .Listings_AmountBlock {
        flex-direction: column;
        justify-content: flex-start !important;
    }
}

.Listings_Price {
    color: #4ECBD7 !important;
    background-color: #4ECBD730 !important;
    font-family: 'Gilroy-SemiBold' !important;
    font-size: 20px;
    padding: 5px;
    border-radius: 5px;
    display: flex;
}

.Listings_Price_Condition {
    font-size: larger;
}

.Listings_Condition {
    font-size: 10px;
    color: gray;
    padding: 10px 0px 0px;
}

.ImagePlaceholder {
    max-width: 130px !important;
    min-height: 70px;
    max-height: 90px !important;
    border-radius: 10px;
    background-color: #EEEEEE80;
    display: flex;
    justify-content: center;
    align-items: center;
}

.New_Listings_DetailsBlock {
    display: flex;
    gap: 15px;
    justify-content: space-between;
    align-items: center;
    line-height: 1.5;
    padding: 10px 0px 0px;
    font-weight: bold;
}

.New_Listings_DetailsBlock>div:not(:first-child) {
    display: flex;
    gap: 15px;
}

.Quantity {
    font-weight: bold;
    color: var(--userPrimaryColor) !important;
    font-size: 10px !important;
}

.QBlock {
    display: flex;
    align-items: center;
    flex-direction: column;
}

.KBlock {
    padding: 10px 0px;
}

.QuantityIcon {
    color: var(--userPrimaryColor) !important;
    font-size: 20px !important;
}

.Listings_DetailsBlock {
    display: flex;
    gap: 15px;
    justify-content: space-between;
    line-height: 1.5;
}

.Listings_DetailsBlock>div {
    font-weight: bold;
    display: flex;
    gap: 15px;
}

/* WILL BE REMOVED WHEN WE PLAN TO SHOW VENDOR DETAILS */
.Listings_VendorInfo {
    display: none !important;
}

.Listings_VendorInfo {
    line-height: 2;
    text-align: left;
}

.Listings_VendorInfo>div:first-child {
    font-family: "Gilroy-SemiBold" !important;
}

.VendorContactName {
    font-family: "Gilroy-SemiBold" !important;
    font-size: larger;
    color: var(--userPrimaryColor) !important;
    line-height: 1.5 !important;
}

.VendorName {
    line-height: 1 !important;
    font-size: medium !important;
}

.Hide {
    display: none !important;
}

.VendorActions {
    display: flex;
    gap: 10px;
    margin: 20px 0px 10px;
}

.Listings_AssetImage {
    min-height: 90px !important;
    max-height: 90px !important;
}