.tableBody{
    overflow-x: auto hidden;
}

.tableBody::-webkit-scrollbar {
    width: 8px;
}

.tableBody::-webkit-scrollbar-track {
    background: #f5f5f5;
}

.tableBody::-webkit-scrollbar-thumb {
    background-color: darkgrey;
    border-radius: 20px;
    border: 2px solid #f5f5f5;
}

.tableBody tbody tr:hover{
    cursor: pointer;
}