@import url("../../../assets/styles/fonts.css");

.NotificationsBody {
    color: var(--themeFontColor) !important;
}

.NotificationTitle {
    font-weight: bolder;
    font-size: 20px;
    padding: 2% 0%;
    font-family: 'Gilroy-SemiBold' !important;
}

.NotificationTitleInfo {
    font-weight: bold;
    text-transform: uppercase;
}

.NotificationDetailsTitle {
    text-transform: uppercase;
    font-weight: bolder;
    padding: 1% 0%;
    text-align: left;
    font-family: 'Gilroy-SemiBold' !important;
}

.NotificationDetailsBlock {
    box-shadow: 0px 1px 10px 0px rgba(68, 126, 183, 0.25);
    border-radius: 5px;
    background: var(--themePrimaryColor) !important;
}

.NotificationGridBlock {
    display: grid;
    grid-gap: 1rem;
    width: 80%;
    margin: auto;
}

.NotificationDetailsGrid {
    display: grid;
    grid-template-columns: repeat(2, 1fr) !important;
    grid-gap: 1rem;
    min-width: fit-content;
}

.Notification_RequirementListContainer {
    box-shadow: 0px 1px 10px 0px rgba(68, 126, 183, 0.25);
    padding: 2%;
    margin-top: 2%;
    background: var(--themePrimaryColor) !important;
    border-radius: 10px;
    margin-left: 10%;
    margin-right: 10%;
}

:where(.css-dev-only-do-not-override-1dbjje).ant-table-wrapper .ant-table-thead >tr>th, :where(.css-dev-only-do-not-override-1dbjje).ant-table-wrapper .ant-table-thead >tr>td {
    color: white;
}


@media (max-width: 1100px) {
    .NotificationGridBlock {
        width: 90%;
    }

    .InvoicingInstruction{
        width: 60%;
    }
}

@media (max-width: 770px) {
    .NotificationDetailsGrid {
        grid-template-columns: repeat(1, 1fr) !important;
    }

    .NotificationGridBlock {
        width: 100%;
    }

    .InvoicingInstruction{
        grid-template-columns: repeat(1, 1fr);
        width: 100%;
    }
}

.InstructionsTitle {
    text-transform: uppercase;
    font-weight: bold;
    padding: 2% 0% 1%;
    font-size: large;
    font-family: 'Gilroy-SemiBold' !important;
}