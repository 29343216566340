@import url('../../assets/styles/fonts.css');

.Listings {
    display: flex;
    flex-direction: column;
    padding: 30px;
    gap: 10px;
    background: var(--themePrimaryColor) !important;
    box-shadow: 0px 0px 9px rgba(68, 126, 183, 0.01), 0px 0px 8px rgba(68, 126, 183, 0.05), 0px 0px 6px rgba(68, 126, 183, 0.09), 0px 0px 3px rgba(68, 126, 183, 0.1);
    border-radius: 10px;
}

.Listings>div:first-child {
    max-height: 400px;
}

.AssetDetails {
    display: flex;
    flex-direction: column;
    text-align: start;
    border-top: 1px solid var(--userHighlightColor) !important;
    width: 100%;
    padding: 10px 0px;
    line-height: 1.5;
}

.AssetDetails>div:not(:last-child) {
    width: 100%;
    display: flex;
    justify-content: space-between;
}

/* Year Make Price row */
.AssetDetails>div:first-child {
    font-family: 'Gilroy-SemiBold' !important;
    color: var(--userPrimaryColor) !important;
    font-size: 22px;
}

/* Price block */
.AssetDetails>div:first-child>div:last-child {
    color: #4ECBD7 !important;
    background-color: #4ECBD730 !important;
    font-family: 'Gilroy-SemiBold' !important;
    font-size: larger;
    padding: 5px;
    border-radius: 5px;
    font-size: 20px;
}

.AssetDetails>div:nth-child(2) {
    color: var(--userPrimaryColor) !important;
}

.AssetDetails>div:nth-child(2)>div:last-child {
    color: gray;
}

/* Kms-VIN/Serial row */
.AssetDetails>div:last-child {
    display: flex;
    gap: 20px;
    padding: 20px 0px 10px;
    border-bottom: 1px solid var(--userHighlightColor) !important;
    justify-content: space-between;
}

.AssetDetails>div:last-child>div {
    text-align: center;
}

.AssetDetails>div:last-child>div>div:first-child {
    font-family: 'Gilroy-SemiBold' !important;
}

.AssetDetails>div:last-child>div>div:last-child {
    font-size: 12px;
}

.AssetDescription {
    display: flex;
    flex-direction: column;
    gap: 10px;
    justify-content: flex-start;
    align-items: flex-start;
}

.AssetDescription>:first-child {
    color: gray;
    font-size: 14px;
    padding: 0px 0px 10px;
}

.AssetDescription>:last-child {
    text-align: left;
}

.VendorInfo {
    text-align: left;
    width: 100%;
}

.VendorInfo>div:first-child {
    color: gray;
    font-size: 14px;
    padding: 0px 0px 10px;
}

.VendorInfo>div:last-child {
    display: flex;
    justify-content: space-between;
    gap: 20px;
}

.VendorInfo>div:last-child>div:first-child>div:first-child {
    font-family: 'Gilroy-SemiBold' !important;
    color: var(--userPrimaryColor) !important;
    font-size: 22px;
}

.VendorInfo>div:last-child>div:first-child>div:nth-child(2) {
    font-size: 20px;
}

.VendorInfo>div:last-child>div:first-child>div:nth-child(3) {
    line-height: 2.5;
}

.VendorInfo>div:last-child>div:last-child {
    display: none;
}

.VendorActions {
    display: flex;
    flex-direction: row;
    gap: 10px;
}

@media screen and (max-width: 950px) {
    .VendorInfo>div:last-child {
        flex-direction: column;
    }
}

@media screen and (max-width: 700px) {
    .VendorActions {
        flex-direction: column;
    }
}

.Hide {
    display: none;
}

.SlideIndex {
    font-size: 13px;
    color: gray;
}

.Interested_Button {
    background-color: var(--themePrimaryColor) !important;
    border: 2px solid var(--userPrimaryColor) !important;
    color: var(--userPrimaryColor) !important;
    font-weight: bold;
}

.Listings_Footer {
    display: flex;
    gap: 5px;
    margin: 5px 0px;
}