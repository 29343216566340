.Upload_Button {
    display: flex;
    justify-content: flex-end;
    margin: 1.5% 0% 0%;
    height: 10%;
}

.UpdateMultiple_Container {
    margin-left: auto;
    padding-right: 2%;
}

.Button_Container {
    margin-right: auto;
}

@media (max-width: 450px) {
    .Upload_Button {
        display: flex;
        flex-direction: column;
        gap: 20px;
    }

    .Button_Container {
        margin-right: 0px;
    }

    .UpdateMultiple_Container {
        margin-left: 0px;
        padding-right: 0px;
        width: 100% !important;
    }

    .Update_Multiple_Button {
        width: 100% !important;
    }

    .Step2_Button {
        display: flex;
        flex-direction: column;
        gap: 20px;
    }

    .Back_Button {
        width: 100% !important;
    }

    .Skip_To_Uploading_Button {
        width: 100% !important;
    }
}

.Step1_Title {
    font-size: 14px;
    color: var(--userPrimaryColor);
    display: flex;
    align-items: center;
}

.Step2_Title {
    font-size: 14px;
    color: var(--userPrimaryColor);
}

.Fill_Container {
    display: grid;
    height: 50px;
    grid-template-columns: 26% 60% 10%;
    grid-gap: 2%;
}

.Fill_Button {
    width: 100%;
    height: inherit;
    background-Color: var(--userPrimaryColor);
    color: white;
}

.Step2_Button {
    display: flex;
    justify-content: space-between;
    margin: 1.5% 0% 0%;
    height: 10%;
}

.innerButtons {
    display: flex;
    justify-content: space-between;
}

.Update_Multiple_Button {
    height: 40px;
    width: 200px;
}

.Skip_To_Uploading_Button {
    background: rgba(142, 206, 174, 1);
    height: 40px;
    width: 200px;
}

.Back_Button {
    font-weight: bold;
    color: var(--userPrimaryColor) !important;
    height: 40px;
    width: 147px;
    box-shadow: 0px 1px 5px 0px var(--userHighlightColor) !important;
    border: 2px solid var(--userHighlightColor) !important;
}

.test>.ant-select>.ant-select-selector {
    height: 50px !important;
}

.test>.ant-select>.ant-select-selector>.ant-select-selection-item {
    height: 50px !important;
}

.uploadGrid {
    display: grid;
    grid-template-columns: 40% 60%;
    margin: auto;
}

.platingProvinceRow {
    width: 100px !important;
    display: flex;
}

.modal {
    max-width: 90%;
    /* min-height: 90%; */
    overflow: hidden;
}

.modalContent {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%
}

.modalTitle {
    display: grid;
    grid-template-columns: 30px auto;
    /* height: 5px; */
}

.modelDetails {
    height: 90% !important;
    min-height: 60vh;
}

.modelDetailsResult {
    height: 90%;
    min-height: 68vh;
}

.modalSteps {
    display: flex;
    font-size: x-large;
    font-weight: bold
}

.modalSubTitle {
    font-size: larger;
    /* margin-top: 1%; */
    font-weight: bold
}

.step1Table {
    max-height: 550px;
    overflow-y: hidden;
    flex-grow: 1;
    display: flex;
    justify-content: center;
}

.uploadFileList {
    margin: 2% 0%;
    max-height: 35vh;
    overflow-y: auto;
}

.selectToFillHeading {
    margin-top: 2%;
    margin-bottom: 1%
}

.tableGrid {
    display: grid;
    height: 50px;
    grid-template-columns: 26% 60% 10%;
    grid-gap: 2%;
}

.resultScreen {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.step2Title {
    font-size: larger;
    margin-top: 1%;
    font-weight: bold;
}