.SaveButton {
    display: block;
    background: var(--userPrimaryColor) !important;
    color: white !important;
}

.SaveButton:hover {
    display: block;
    background: var(--userHighlightColor) !important;
    color: var(--userPrimaryColor) !important;
}

.clientInfo {
    font-size: 18px;
    color: var(--themeFontColor) !important;
}

@media (max-width: 770px) {
    .clientGrid {
        display: grid;
        grid-template-columns: auto;
    }

    .clientGridButtons {
        display: flex;
        align-items: center;
        justify-content: space-around;
    }

    .notificationGrid {
        width: 100%;
        display: grid;
        grid-template-columns: 90%;
        grid-gap: 1rem;
        text-align: left;
        margin: 5%;
    }

    .clientInfo {
        padding: 5% 0% 5% 5%;
        font-size: 18px;
    }
}

@media (min-width: 1200px) {
    .clientInfo {
        font-size: 18px;
    }
}

@media (min-width: 992px) and (max-width: 1199px) {
    .clientInfo {
        font-size: 18px;
    }
}

.clientInfo_Details {
    display: flex;
    grid-gap: 1rem;
    line-height: 2;
    font-size: 20px;
}

.clientInfo_Details1 {
    display: flex;
    flex-direction: column;
    line-height: 2;
    font-size: 20px;
}

.clientInfo_Loc {
    display: flex;
    grid-gap: 1rem;
    line-height: 1.5;
    align-items: flex-start;
    text-align: left;
    font-size: 20px;
}

.clientButtons {
    min-width: fit-content;
    width: 100px;
    background-color: #FF5555;
    color: white;
    border: 2px solid #FF5555;
}

.clientButtons:hover:not(:disabled) {
    background-color: transparent !important;
    color: #FF5555 !important;
    border: 2px solid #FF5555 !important;
}

.clientGrid {
    display: grid;
    grid-template-columns: auto 230px;
}

.clientGridButtons {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.clientGrid_Info {
    display: flex;
}

.clientGrid_InfoName {
    font-size: 28px;
    font-weight: bold;
    min-width: fit-content;
    color: var(--themeFontColor) !important;
}

.clientGrid_InfoName1 {
    display: flex;
    gap: 1rem;
    font-size: 20px;
    min-width: fit-content;
    color: var(--themeFontColor) !important;
}

.clientGrid1 {
    display: grid;
    grid-template-columns: auto auto;
    text-align: start;
}

@media screen and (max-width: 780px) {
    .clientGrid {
        grid-template-columns: 100% !important;
    }

    .clientGridButtons {
        display: flex;
        align-items: center;
        justify-content: start;
        gap: 20px;
    }

    .clientGrid1 {
        display: grid;
        grid-template-columns: auto;
        text-align: start;
    }

}

.AutoFill_AlertContainer {
    display: grid;
    grid-template-columns: 20px auto;
    align-items: start;
    gap: 10px;
    font-family: 'Gilroy-UltraLight' !important;
    background: var(--userHighlightColor) !important;
    padding: 5px;
    border-radius: 8px;
    margin-bottom: 10px;
}

.CheckCircle {
    color: #8ECEAE;
    font-size: 20px;
    background: white;
    border-radius: 50%;
}

.Edited_Text {
    font-size: 25;
    font-weight: bolder;
    color: 'black';
    line-height: 1.5;
    display: flex;
}