@import url("../../assets/styles/fonts.css");



.ListItemEnd,
.ListItemSelectedEnd {
    display: grid;
    grid-template-columns: 75% 25%;
    padding: 5%;
}

.ListItemEnd:hover,
.ListItemSelectedEnd {
    font-weight: bold;
    color: black;
    background-color: var(--userHighlightColor) !important;
    border-radius: 0px 0px 5px 5px !important;
}

.ListItem,
.ListItemSelected {
    padding: 10px 20px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 15px;
    font-size: 18px;
    font-family: 'Gilroy-Regular' !important;
}

.ListItem:hover,
.ListItemSelected {
    background-color: var(--userHighlightColor) !important;
    font-family: 'Gilroy-SemiBold' !important;
    font-weight: bold;
    color: black;
}