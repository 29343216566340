@import url('../../assets/styles/fonts.css');

.HeaderContainer {
    display: grid;
    grid-template-columns: 40px auto;
    align-items: center;
    font-family: 'Gilroy-SemiBold' !important;
}

.HeaderName {
    display: grid;
    grid-template-columns: auto auto;
    gap: 10px;
    justify-content: space-between;
}

@media screen and (max-width: 768px) {
    .HeaderName {
        grid-template-columns: auto;
    }
}

.HeaderName span {
    color: var(--userPrimaryColor) !important;
    font-family: 'Gilroy-UltraLight' !important;
}