.Resources_Grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 20px 30px;
    padding: 20px 10px;
    /* gap: 5%; */
}

@media screen and (max-width: 910px) {
    .Resources_Grid {
        grid-template-columns: repeat(1, 1fr);
    }
}

.Resources_IndividualGrid {
    min-width: fit-content;
    background: var(--themePrimaryColor) !important;
    color: var(--themeFontColor) !important;
    box-shadow: 0px 1px 10px 0px rgba(68, 126, 183, 0.25);
    border-radius: 10px;
    padding: 10% 2%;
    line-height: 3;
}

.Resources_IndividualGrid_Single {
    min-width: fit-content;
    background: var(--themePrimaryColor) !important;
    color: var(--themeFontColor) !important;
    box-shadow: 0px 1px 10px 0px rgba(68, 126, 183, 0.25);
    border-radius: 10px;
    padding: 10% 2%;
    line-height: 3;
}

.Resources_IndividualGrid_Single:hover {
    outline: 2px solid var(--userPrimaryColor) !important;
    cursor: pointer;
}

.Resources_IndividualGrid_Header {
    font-weight: bold;
    font-size: larger;
}

.ButtonContainer {
    display: flex;
    justify-content: space-evenly;
}

.BrandedButton {
    min-width: fit-content;
    width: 150px;
    height: 40px;
}

.WhiteLabelButton {
    min-width: fit-content;
    width: 150px;
    height: 40px;
    background: var(--userSecondaryColor) !important;
    color: #FFFFFF !important;
}

.WhiteLabelButton:hover {
    background: var(--userSecondaryHoverColor) !important;
    color: #FFFFFF !important;
}

.HideContainer {
    display: none;
}

.Resources_BeaconPolicies {
    min-width: fit-content;
    background: var(--themePrimaryColor) !important;
    color: var(--themeFontColor) !important;
    box-shadow: 0px 1px 10px 0px rgba(68, 126, 183, 0.25);
    border-radius: 10px;
    padding: 10% 2%;
    line-height: 3;
}

.Resources_BeaconPolicies:hover {
    outline: 2px solid var(--userPrimaryColor) !important;
    cursor: pointer;
}