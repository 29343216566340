.editButton {
    background: var(--userPrimaryColor) !important;
    width: 100px;
    min-width: fit-content;
}

.SaveButton {
    display: flex;
    justify-content: center;
}

.editButton:hover {
    background: var(--userHighlightColor) !important;
    color: var(--userPrimaryColor) !important;
}

.AutoFill_AlertContainer {
    display: grid;
    grid-template-columns: 20px auto;
    align-items: start;
    gap: 10px;
    font-family: 'Gilroy-UltraLight' !important;
    background: var(--userHighlightColor) !important;
    padding: 5px;
    border-radius: 8px;
    margin-bottom: 10px;
}

.ExclamationCircle {
    color: #EDCB00;
    font-size: 20px;
    background: white;
    border-radius: 50%
}

.Edit_AlertText {
    font-size: 15;
    font-weight: 100;
    color: #3F3D33;
    line-height: 1.5;
}