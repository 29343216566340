.DraggerInfo-Header {
    font-weight: bold;
    margin-top: 2%;
}

.DraggerInfo-SubHeader {
    font-weight: bold;
}

.browseButton {
    margin-top: 2%;
    color: var(--userPrimaryColor) !important;
    border: 1px solid var(--userHighlightColor) !important;
}

.browseButton:hover {
    border: 1px solid var(--userPrimaryColor) !important;
}