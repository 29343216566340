@import url("../../assets/styles/fonts.css");

.JoinUs_Container {
    display: grid;
    grid-template-columns: 25% 75%;
    background-color: #F4F4F4 !important;
    height: 100vh;
    font-family: 'Gilroy-Regular' !important;
}

.JoinUs_StaticHolder {
    background: url("https://lanternpublicstaticfiles.blob.core.windows.net/main/Beacon_JoinUs.svg");
    background-size: cover;
    height: 100vh;
}

.JoinUs_StaticHeader {
    display: none;
}

@media screen and (max-width: 768px) {
    .JoinUs_Container {
        grid-template-columns: 100%;
    }

    .JoinUs_StaticHolder {
        background: url("https://lanternpublicstaticfiles.blob.core.windows.net/main/Beacon_JoinUs.svg");
        background-size: cover;
        height: 15vh;
        padding-bottom: 2%;
    }

    .JoinUs_StaticHeader {
        display: flex;
        justify-content: center;
        align-items: flex-end;
        font-weight: bold;
        font-size: xx-large;
        color: white;
        height: 100%;
    }

    .JoinUs_Header {
        display: none;
    }
}

.JoinUs_Form {
    padding: 5%;
    overflow-y: auto;
}

.JoinUs_Header {
    text-align: left;
    font-weight: bold;
    font-size: 36px;
}