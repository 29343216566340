.Home_Header {
    display: flex !important;
    justify-content: space-between !important;
}

.Home_HeaderGreetings {
    color: #898989 !important;
    padding-left: 10px !important;
}

.Home_HeaderDate {
    color: #898989 !important;
    font-weight: normal !important;
}

@media screen and (max-width: 750px) {
    .Home_Header {
        display: grid !important;
        grid-template-columns: 1fr !important;
    }
}